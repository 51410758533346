<template>
	<div>
		<div class="bottom">
			<div class="bottomContent">
				<div>
					<!-- <img src="@/static/bottomlogo.png" alt="" /> -->
					<!-- <image src="@/static/logos.jpg" style="width:552rpx;height:198rpx;"/>  -->
				</div>
				<div class="bottomRight">
					<ul class="bottomItem">
						<a href="#">返回顶部</a>
						<a href="bottom_detail.html?type=1">本站公告</a>
						<a href="bottom_detail.html?type=2&name=termsOfservice">服务条款</a>
						<a href="bottom_detail.html?type=2&name=privacyClause">隐私条款</a>
						<a href="bottom_detail.html?type=2&name=memberServices">会员服务</a>
						<a href="bottom_detail.html?type=2&name=advertisingServices">广告服务</a>
						<a href="bottom_detail.html?type=2&name=aboutUs">关于我们</a>
						<a href="bottom_detail.html?type=2&name=contactUs">联系我们</a>
						<!-- <li @click.stop="jumps('data_download', 8)">资料下载</li> -->
					</ul>
					<!-- <div style="color:#ff0000;margin-top:.5vw;font-weight:bold">注意:本站提供船员培训、办证、船员招聘和船员求职的平台,我们网站不招聘船员,船员求职请查看招聘信息里的企业联系方式，不要用以下联系方式!</div> -->
					<div class="bottomQr-item">
						<div class="item">
							<div style="margin-top:.5vw;">网站联系电话:0595-87898878 手机微信:16750591566</div>
							<div class="icp">
								<div  style="margin-top:.5vw;">邮箱:2530191892@qq.com</div>
								<a href="https://beian.miit.gov.cn">闽ICP备2022001715号-2</a>
								<span>Copyright © 2024 福建万洋海瑞教育科技集团有限公司 丨船员管家找船网 </span>
							</div>
							<!-- 网站联系电话:0595-87898878  手机微信： 16750591566
							在线企业客服：邮箱：Mail:2530191892@qq.com
							备案号：闽ICP备2022001715号-2
							版权所有：福建万洋海瑞教育科技集团有限公司丨船员管家找船网 -->
						</div>
						<div class="bottomQr"><img src="@/static/bottom_qrcode.jpg" alt="" style="width:5vw;height:5vw;" /></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin.vue';
export default {
	mixins: [BaseMixin],
	name: 'bottomWin',
	data() {
		return {};
	},
	methods: {
		backTop() {
			// this.getTop();
			// window.location.href = '#top';
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
		},
		// jumps(url, index) {
		// 	this.$u.vuex('tabIndex', index);
		// 	this.$u.route({
		// 		url: url,
		// 		type: 'redirectTo'
		// 	});
		// },
		beian() {
			window.open('https://beian.miit.gov.cn');
		}
	}
};
</script>
<style>
page {
	background: #fafafa;
}
</style>
<style lang="less" scoped>
.bottom {
	// background: #242424;
	// color: #999999;
	cursor: pointer;
	// background-image: url(https://image.crewcn.com/image/bottom.gif) repeat-x !important;
	background: rgba(32, 137, 255, 0.2);
	// padding:0 18.75vw;
	padding-bottom: 1vw;
	margin-top: 2vw;
	text-align: center;
	box-shadow: 0px 16px 26px 0px rgba(6, 27, 51, 0.13);
}

.bottomContent {
	// padding: 1.6458vw 0;
	// display: flex;
	// align-items: flex-start;
}

.bottomRight {
	// padding: 0.52vw 0;
	// margin-left: 46px;
}

.bottomItem {
	padding: 2vw;
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	// padding-left: 16%;
	background: rgba(32, 137, 255, 0.2);
	font-size: .8vw;
}

.bottomItem a {
	padding: 0 1.0416vw;
}

.copyRigth {
	padding: 0 1.0416vw;
	margin-top: 0.5vw;
	font-weight: bold;
}

.bottomQr-item {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: .9vw;

	.icp {
		margin-top: 0.625vw;
		font-weight: bold;
	}

	.icp span {
		padding: 0 1.0416vw;
	}

	.bottomQr {
		margin-left: 1.625vw;
		margin-top: 0.625vw;
		font-weight: bold;
	}
}

// .icp {
//     margin-top: 0.625vw;font-weight:bold
// }
// .icp span {
//     padding: 0 1.0416vw;
// }</style>
