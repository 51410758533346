<template>
  <div>
    <div class="enterprise">
      <div style="background-color: #fff">
        <el-row>
          <gsLeft :userFormW="subform"></gsLeft>
          <el-col :span="20">
            <div class="menuContent">
              <!-- <div v-if="tableData.length==0" class="noTrain">
			<div>您的企业还没有培训信息，请先去发布</div>
			<el-button type="primary" @click.stop="jump('add_training')">新增培训</el-button>
			</div>
			<div v-else> -->
              <div class="trainInfo">
                <div class="trainInfo-title">培训信息</div>
                <!-- <el-button type="default" @click.stop="jump('add_training')">新增培训</el-button> -->
              </div>
              <el-form ref="form" :model="formInline" label-width="4.167vw">
                <el-form-item label="发布日期">
                  <el-col :span="11">
                    <el-date-picker
                      v-model="formInline.createTime"
                      type="datetimerange"
                      align="right"
                      unlink-panels
                      clearable
                      value-format="yyyy-MM-dd hh:mm:ss"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      @change="dateChange"
                    ></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item label="课程状态">
                  <el-button type="text" @click="checkStatus('')">全部</el-button>
                  <el-button type="text" @click="checkStatus(1)">上架中</el-button>
                  <el-button type="text" @click="checkStatus(2)">已下架</el-button>
                </el-form-item>
                <el-form-item label="课程搜索" class="enterprise-search">
                  <el-input
                    v-model="formInline.name"
                    placeholder="搜索课程标题"
                    @input="clearInput"
                  ></el-input>
                  <el-button
                    ref="searchBtn"
                    :type="formInline.name ? 'primary' : 'info'"
                    @click="initData(formInline)"
                    >搜索</el-button
                  >
                </el-form-item>
              </el-form>
              <el-table
                border
                :data="tableData"
                style="width: 100%"
                :header-cell-style="{
                  'background-color': 'rgba(32, 137, 255, 0.07)',
                  color: '#333',
                  'font-size': '0.833vw',
                  'font-weight': '400',
                }"
              >
                <!-- <el-table-column
			      type="selection"
			      width="55">
			    </el-table-column> -->
                <el-table-column
                  prop="name"
                  label="课程标题"
                  width="200"
                ></el-table-column>
                <el-table-column prop="number" label="开课人数"></el-table-column>
                <el-table-column prop="schoolTime" label="开课时间"></el-table-column>
                <el-table-column prop="createTime" label="发布日期">
                  <template slot-scope="scope">
                    {{ $dy_tool.formatDate(scope.row.createTime) }}
                  </template>
                </el-table-column>
                <el-table-column prop="isUp" label="状态">
                  <template slot-scope="scope">
                    {{ scope.row.isUp ? "已上架" : "未上架" }}
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      @click="jump('train_course_details?id=' + scope.row.id)"
                      >查看</el-button
                    >
                    <el-button type="text" @click="handleUp(scope.$index, scope.row)">{{
                      scope.row.isUp ? "下架" : "上架"
                    }}</el-button>
                    <el-button
                      type="text"
                      @click="jump('add_training?id=' + scope.row.id)"
                      >修改</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- <div class="tableBottom">
			<div>
				<el-button type="text" @click="handleEdit(scope.$index, scope.row)">批量上架</el-button>
				<el-button type="text" @click="handleEdit(scope.$index, scope.row)">批量下架</el-button>
			</div> -->
              <!-- <div style="text-align:right;"><el-pagination layout="prev, pager, next, jumper" :total="100"></el-pagination></div> -->

              <div style="text-align: right">
                <el-pagination
                  style="margin-top: 20px; text-align: right"
                  :current-page="currentPage"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="10"
                  layout="prev, pager, next, jumper"
                  :total="total"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                />
              </div>
              <!-- </div> -->
              <!-- </div> -->
            </div>
            <el-dialog title="提示" :visible.sync="showSub" width="30%">
              <span v-if="subform.subscribe" style="margin-right: 20px"
                >是否关闭订阅</span
              >
              <span v-else style="margin-right: 20px">是否开启订阅</span>
              <el-radio v-model="subscription" :label="true">是</el-radio>
              <el-radio v-model="subscription" :label="false">否</el-radio>
              <span slot="footer" class="dialog-footer">
                <el-button @click="showSub = false">取 消</el-button>
                <el-button type="primary" @click="editSub">确 定</el-button>
              </span>
            </el-dialog>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/BaseMixin.vue";
import gsLeft from "@/component/gs_left.vue";
export default {
  mixins: [BaseMixin],
  components: { gsLeft },
  data() {
    return {
      checkIndex: "5-1",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      form: {
        date: "",
        employJob: "选项1",
        search: "",
      },
      options: [
        {
          value: "选项1",
          label: "所有职务",
        },
        {
          value: "选项2",
          label: "机工",
        },
      ],
      tableData: [],
      currentPage: 1,
      total: 1,
      formInline: {
        createTime: "",
        isUp: "",
        name: "",
        page: 0, // 当前页
        size: 10,
      },
      showSub: false,
      subscription: true,
      subform: {},
    };
  },
  methods: {
    initData(formInline) {
      this.subform = {
        id: this.vuex_user.id,
        nickName: this.vuex_user.nickName,
        avatar: this.vuex_user.avatar,
        gender: this.vuex_user.gender,
        openId: this.vuex_user.openId,
        wxOpenid: this.vuex_user.wxOpenid,
        phone: this.vuex_user.phone,
        password: this.vuex_user.password,
        name: this.vuex_user.name,
        simpleName: this.vuex_user.simpleName,
        province: this.vuex_user.province,
        city: this.vuex_user.city,
        district: this.vuex_user.district,
        address: this.vuex_user.address,
        postalCode: this.vuex_user.postalCode,
        website: this.vuex_user.website,
        intro: this.vuex_user.intro,
        subscribe: this.vuex_user.subscribe,
        isPass: this.vuex_user.isPass,
        openAgency: this.vuex_user.openAgency,
        createTime: this.vuex_user.createTime,
        autid: this.vuex_user.autid,
      };
      this.subscription = this.subform.subscribe;
      this.$u.api.companyTrainingPC(formInline).then((res) => {
        this.tableData = res.data.content;
        this.total = res.data.totalElements;
      });
    },
    onSubmit() {
      console.log("submit!");
    },
    dateChange(e) {
      this.initData(this.formInline);
    },
    clearInput(e) {
      if (e == "") {
        this.formInline = {};
        this.formInline.page = 0;
        this.formInline.size = 10;
        this.initData(this.formInline);
      }
    },

    handleUp(index, row) {
      if (row.isUp) {
        this.$u.api.trainingUpChanges({ id: row.id, isUp: !row.isUp }).then((res) => {
          this.uni.showToast({ title: "培训下架成功", icon: "none" });

          this.initData();
        });
      } else {
        this.$u.api.trainingUpChanges({ id: row.id, isUp: !row.isUp }).then((res) => {
          this.uni.showToast({ title: "培训上架成功", icon: "none" });

          this.initData();
        });
      }
    },
    checkStatus(e) {
      if (e == "") {
        this.formInline = {};
        this.formInline.page = 0;
        this.formInline.size = 10;
        this.initData(this.formInline);
      } else if (e == 1) {
        this.formInline.isUp = true;
        this.initData(this.formInline);
      } else if (e == 2) {
        this.formInline.isUp = false;
        this.initData(this.formInline);
      }
    },
    editSub() {
      if (this.subscription) {
        this.$u.api.updateSubscribe(this.subscription + "").then((res) => {
          this.uni.showToast({ title: "订阅开启成功", icon: "none" });
          this.subform.subscribe = true;
          this.$u.vuex("vuex_user", this.subform);
          this.showSub = false;
        });
      } else {
        this.$u.api.updateSubscribe(this.subscription + "").then((res) => {
          this.uni.showToast({ title: "订阅关闭成功", icon: "none" });
          this.subform.subscribe = false;
          this.$u.vuex("vuex_user", this.subform);
          this.showSub = false;
        });
      }
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.initData(this.formInline);
    },
    handleCurrentChange(val) {
      this.formInline.page = val - 1;
      this.initData(this.formInline);
    },
    check(index) {
      this.checkIndex = index;
      if (index == "1") {
        this.jump("enterprise_index");
      } else if (index == "2") {
        this.jump("ec_account_manage");
      } else if (index == "3") {
        this.jump("ec_buy_package");
      } else if (index == "4-1") {
        this.jump("post_recruitment_info");
      } else if (index == "4-2") {
        this.jump("manage_recruitment_info");
      } else if (index == "4-3") {
        this.jump("resume_delivery_received");
      } else if (index == "4-4") {
        this.jump("collected_resume");
      } else if (index == "5") {
        this.jump("ec_training_order");
      } else if (index == "5-3") {
        this.jump("training_school");
      } else if (index == "7") {
        this.jump("corporate_announcements");
      } else if (index == "8") {
        // this.$u.api.companyLoginInfo().then(res => {
        // 	if(res.data.openAgency) {
        if (this.vuex_user.openAgency) {
          this.jump("ec_mediation_guarantee");
        } else {
          this.uni.showToast({ title: "您未开通中介担保", icon: "none" });
        }
        // })
      } else if (index == "9") {
        this.jump("published_data");
      } else if (index == "10") {
        this.showSub = true;
      }
    },
  },
};
</script>
<style>
page {
  background: #fafafa;
}
.enterprise-search >>> .el-form-item__content {
  display: flex;
}
</style>
<style lang="less" scoped>
.enterprise {
  margin: 10px 18.75vw 6.25vw;

  .menuContent {
    background: #fff;
    padding: 1.5625vw 2.083vw;
  }
}
.noTrain {
  // position: absolute;
  padding: 11.979vw;
  text-align: center;
  // left: 360px;
  div {
    font-size: 0.833vw;
  }
  button {
    font-size: 0.833vw;
    margin-top: 3.6458vw;
    width: 6.25vw;
  }
}
.trainInfo {
  // width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
  .trainInfo-title {
    font-size: 1.0416vw;
  }
  button {
    margin: 0;
    width: 100px;
    background-color: #2089ff;
    font-size: 14px;
    color: #ffffff;
  }
}

.el-form {
  margin-top: 2.604vw;
  .el-input {
    width: 15.625vw;
    margin-right: 0.52vw;
  }
}
.el-table {
  margin-top: 2.083vw;
}
.tableBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.833vw 0 6.25vw;
}
</style>
