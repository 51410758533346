<template>
	<div class="enterprise">
		<div style="background-color: #fff;">
			<el-row>
				<gsLeft :userFormW="subform"></gsLeft>
				<el-col :span="20">
					<div class="menuContent">

						<div class="index">
							<div class="status">
								<div class="status-title">培训情况</div>
								<div class="rowNum">
									<div class="rowItem">
										<div class="number">{{trainingSituation.totalTraining}}</div>
										<div class="type">培训订单总数</div>
									</div>
									<div class="rowItem">
										<div class="number">{{trainingSituation.completedOrderNum}}</div>
										<div class="type">已完结订单数</div>
									</div>
									<div class="rowItem">
										<div class="number">{{(trainingSituation.totalOrderAmount).toFixed(2)}}</div>
										<div class="type">订单总额</div>
									</div>
								</div>
							</div>
							<div class="status">
								<div class="status-title">招聘情况</div>
								<div class="rowNum">
									<div class="rowItem">
										<div class="number">{{recruitmentSituation.resumePublishedNum}}</div>
										<div class="type">招聘发布总数</div>
									</div>
									<div class="rowItem">
										<div class="number">{{recruitmentSituation.jobNum}}</div>
										<div class="type">职务总数</div>
									</div>
									<div class="rowItem">
										<div class="number">{{recruitmentSituation.resumeReceivedNum}}</div>
										<div class="type">收到的简历投递数</div>
									</div>
								</div>
							</div>
							<div class="status">
								<div class="status-title">中介担保情况</div>
								<div class="rowNum">
									<div class="rowItem">
										<div class="number">{{guaranteeSituation.totalOrder}}</div>
										<div class="type">订单总数</div>
									</div>
									<div class="rowItem">
										<div class="number">{{guaranteeSituation.boardNum}}</div>
										<div class="type">已上船人数</div>
									</div>
									<div class="rowItem">
										<div class="number">{{(guaranteeSituation.totalOrderAmount).toFixed(2)}}</div>
										<div class="type">订单总额</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<el-dialog title="提示" :visible.sync="showSub" width="30%">
						<span v-if="subform.subscribe" style="margin-right:20px">是否关闭订阅</span>
						<span v-else style="margin-right:20px">是否开启订阅</span>
						<el-radio v-model="subscription" :label="true">是</el-radio>
						<el-radio v-model="subscription" :label="false">否</el-radio>
						<span slot="footer" class="dialog-footer">
							<el-button @click="showSub = false">取 消</el-button>
							<el-button type="primary" @click="editSub">确 定</el-button>
						</span>
					</el-dialog>

				</el-col>

			</el-row>
		</div>

	</div>
</template>

<script>
	import BaseMixin from '@/mixins/BaseMixin.vue'
	import gsLeft from "@/component/gs_left.vue";

	export default {
		mixins: [BaseMixin],
		components: {
			gsLeft
		},
		data() {
			return {
				checkIndex: '1',
				trainingSituation: { //培训情况
					totalTraining: 0,
					completedOrderNum: 0,
					totalOrderAmount: 0
				},
				recruitmentSituation: { //招聘情况
					resumePublishedNum: 0,
					jobNum: 0,
					resumeReceivedNum: 0
				},
				guaranteeSituation: { //中介担保情况
					totalOrder: 0,
					boardNum: 0,
					totalOrderAmount: 0
				},
				showSub: false,
				subscription: true,
				subform: {}
			}
		},
		methods: {
			initData() {
				this.subform = {
					id: this.vuex_user.id,
					nickName: this.vuex_user.nickName,
					avatar: this.vuex_user.avatar,
					gender: this.vuex_user.gender,
					openId: this.vuex_user.openId,
					wxOpenid: this.vuex_user.wxOpenid,
					phone: this.vuex_user.phone,
					password: this.vuex_user.password,
					name: this.vuex_user.name,
					simpleName: this.vuex_user.simpleName,
					province: this.vuex_user.province,
					city: this.vuex_user.city,
					district: this.vuex_user.district,
					address: this.vuex_user.address,
					postalCode: this.vuex_user.postalCode,
					website: this.vuex_user.website,
					intro: this.vuex_user.intro,
					subscribe: this.vuex_user.subscribe,
					isPass: this.vuex_user.isPass,
					openAgency: this.vuex_user.openAgency,
					createTime: this.vuex_user.createTime,
					autid: this.vuex_user.autid
				}
				this.subscription = this.subform.subscribe
				// 培训情况
				this.$u.api.orderList().then(res => {
					this.trainingSituation.totalTraining = res.data.totalElements
					res.data.content.forEach(e => {
						if (e.status == 3) {
							this.trainingSituation.completedOrderNum += 1
						}
						this.trainingSituation.totalOrderAmount += e.fee
					})

				})
				// 招聘情况
				this.$u.api.getSend().then(res => {
					this.recruitmentSituation.resumeReceivedNum = res.data.totalElements
				})
				this.$u.api.companyRecruit().then(res => {
					let len = res.data.length
					let nameArr = []
					res.data.forEach(e => {
						if (nameArr.indexOf(e.job) == -1) {
							nameArr.push(e.job)
						}
					})
					this.recruitmentSituation.jobNum = nameArr.length

					this.recruitmentSituation.resumePublishedNum = len
					// 
				})
				// 中介担保情况
				this.$u.api.agencyCompanyOrder().then(res => {
					this.guaranteeSituation.totalOrder = res.data.totalElements
					res.data.content.forEach(e => {
						this.guaranteeSituation.totalOrderAmount += e.totalFee
						if (e.status == 5) {
							this.guaranteeSituation.boardNum += 1
						}
					})

				})
				// this.$u.api.companyLoginInfo().then(res => {
				// 	
				// 	this.$u.vuex('vuex_user', res.data)
				// })
			},
			editSub() {
				if (this.subscription) {
					this.$u.api.updateSubscribe(this.subscription + '').then(res => {
						this.uni.showToast({
							title: '订阅开启成功',
							icon: 'none'
						})
						this.subform.subscribe = true
						this.$u.vuex('vuex_user', this.subform)
						this.showSub = false
					})
				} else {
					this.$u.api.updateSubscribe(this.subscription + '').then(res => {
						this.uni.showToast({
							title: '订阅关闭成功',
							icon: 'none'
						})
						this.subform.subscribe = false
						this.$u.vuex('vuex_user', this.subform)
						this.showSub = false
					})
				}

			},
			check(index) {
				this.checkIndex = index
				if (index == '1') {
					this.jump('enterprise_index')
				} else if (index == '2') {
					this.jump('ec_account_manage')
				} else if (index == '3') {
					this.jump('ec_buy_package')
				} else if (index == '4-1') {

					if (this.vuex_user.isPass === true && this.vuex_user.autid != 2) {
						this.uni.showToast({
							title: '您的企业审核暂未通过',
							icon: 'none'
						});
						return
					}
					this.jump('post_recruitment_info')
				} else if (index == '4-2') {
					this.jump('manage_recruitment_info')
				} else if (index == '4-3') {
					this.jump('resume_delivery_received')
				} else if (index == '4-4') {
					this.jump('collected_resume')
				} else if (index == '5-1') {
					this.jump('ec_training_info')
				} else if (index == '5') {
					this.jump('ec_training_order')
				} else if (index == '5-3') {
					this.jump('training_school')
				} else if (index == '7') {

					if (this.vuex_user.isPass === true && this.vuex_user.autid != 2) {
						this.uni.showToast({
							title: '您的企业审核暂未通过',
							icon: 'none'
						});
						return
					}
					this.jump('corporate_announcements')
				} else if (index == '8') {
					// this.$u.api.companyLoginInfo().then(res => {
					// 	if(res.data.openAgency) {
					if (this.vuex_user.openAgency) {
						this.jump('ec_mediation_guarantee')
					} else {
						this.uni.showToast({
							title: '您未开通中介担保',
							icon: 'none'
						})
					}
					// })
				} else if (index == '9') {
					this.jump('published_data')
				} else if (index == '10') {
					this.showSub = true
				}
			},
		}
	}
</script>
<style>
	page {
		background: none;
	}

	.el-submenu .el-menu-item {
		min-width: 100%;
	}
</style>
<style lang="less" scoped>
	.enterprise {
		margin: 0 auto;
		width: 68.5vw;
		padding-top: 50px;
		background: #fff;
		box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);

		.menuContent {
			background: #fff;
			padding: 0.5vw 2.083vw 1.5625vw;
		}
	}

	.index {
		.status {
			margin-top: 110px;

			.status-title {
				font-size: 20px;
			}

			.rowNum {
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-align: center;
				margin: 60px 100px 100px;

				.rowItem {
					text-align: center;
					width: 16%;

					.number {
						font-size: 2.08vw;
						color: #2089FF;
					}

					.type {
						margin-top: 30px;
						font-size: 14px;
						color: #999999;
					}
				}

			}
		}

		:first-child {
			margin-top: 0;
		}
	}
</style>