
import {Toast  } from 'vant';
import uni from './uni'
export function showToast(toast) {
    // Message.success(toast.title)
    Toast(toast.title || toast || '提示')
    // this.uni.showToast({
    //     title: toast.title,
    //     icon: toast.icon || 'none',
    //     image: toast.image,
    //     mask: toast.mask || false,
    //     duration: toast.duration || 3000
    // })
}
export function hideToast() {
    uni.hideToast()
}
export function showLoading(loading,hideTime) {
    uni.showLoading({
        title: loading.title || '加载中...',
        mask: loading.mask || false
    })
    if (hideTime) {
        setTimeout(() => {
          uni.hideLoading()
        }, hideTime)
    }
}
export function hideLoading() {
    this.uni.hideLoading()
}
export function showModal(modal) {
    return new Promise((resolve, reject) => {
        uni.showModal({
            title: modal.title,
            content: modal.content,
            showCancel: modal.showCancel,
            cancelText: modal.cancelText || '取消',
            cancelColor: modal.cancelColor || '#000000',
            confirmText: modal.confirmText || '确定',
            confirmColor: modal.confirmColor || '#3CC51F',
            success: (res) => {
                if (res.confirm) {
                    resolve(res)
                } else if (res.cancel) {
                    console.log(res)
                    reject(res)
                }
            }
        })
    })
}
export function showActionSheet(actionSheet) {
    return new Promise((resolve, reject) => {
        this.uni.showActionSheet({
            itemList: actionSheet.itemList,
            itemColor: actionSheet.itemColor || '#000000',
            success: (res) => {
                resolve(res)
            },
            fail: () => {
                reject('出错了')
            }
        })
    })
}
export default { showToast, hideToast, showLoading, hideLoading, showModal, showActionSheet }