import axios from 'axios';
const config  = require('@/config/config')
const hrefHost = process.env.NODE_ENV === "development" ? config.devHref :config.prodHref

export function vuex(key = 'vuex_user', value = null) {
  if (value !== null) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export function route(obj = {
  url: 'login'
}) {
  if (typeof obj.url === 'string' && obj.url.indexOf('?') !== -1) {
    // url存在？号
    const urlName = obj.url.split('?')[0];
    const urlParam = obj.url.split('?')[1];
    window.location.href = hrefHost + urlName + '.html?' + urlParam
  } else {
    window.location.href = hrefHost + obj.url + '.html'
  }
}
export default {
  http: axios,
  vuex,
  route
}