<template>
	<div>
		<div class="enterprise">
			<div style="background-color: #fff;">
				<el-row>
					<gsLeft :userFormW="subform"></gsLeft>
					<el-col :span="20">
						<div class="menuContent">

							<div style="font-size: 1.0416vw;">已收藏简历</div>
							<div>
								<el-table border :data="tableData" style="width: 100%;margin-top: 50px;"
									:header-cell-style="{ 'background-color': 'rgba(32, 137, 255, 0.07)', 'color': '#333', 'font-weight': '400' }">
									<el-table-column prop="resume.userName" label="姓名">
									</el-table-column>
									<el-table-column prop="resume.expectJob" label="应聘职务">
									</el-table-column>
									<el-table-column prop="resume.age" label="年龄" width="80">
									</el-table-column>
									<el-table-column prop="resume.sailingYear" label="海龄" width="80">
									</el-table-column>
									<el-table-column prop="resume.certificate" label="证书类型">
									</el-table-column>
									<el-table-column prop="resume.sailLine" label="航区">
									</el-table-column>
									<el-table-column prop="createTime" label="投递日期">
										<template slot-scope="scope">
											{{ $dy_tool.formatDate(scope.row.createTime, 'yyyy-MM-dd') }}
										</template>
									</el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<el-button type="text"
												@click="jump('resumeDetail/' + scope.row.resume.id+'.html')">查看</el-button>
											<el-button type="text"
												@click.stop="collected(scope.$index, scope.row)">取消收藏</el-button>
										</template>
									</el-table-column>
								</el-table>
								<el-pagination style="margin-top:20px;text-align:right;" :current-page="currentPage"
									:page-sizes="[10, 20, 50, 100]" :page-size="10" layout="prev, pager, next, jumper"
									:total="total" @size-change="handleSizeChange"
									@current-change="handleCurrentChange" />
							</div>
						</div>

						<el-dialog title="提示" :visible.sync="showSub" width="30%">
							<span v-if="subform.subscribe" style="margin-right:20px">是否关闭订阅</span>
							<span v-else style="margin-right:20px">是否开启订阅</span>
							<el-radio v-model="subscription" :label="true">是</el-radio>
							<el-radio v-model="subscription" :label="false">否</el-radio>
							<span slot="footer" class="dialog-footer">
								<el-button @click="showSub = false">取 消</el-button>
								<el-button type="primary" @click="editSub">确 定</el-button>
							</span>
						</el-dialog>
					</el-col>

				</el-row>
			</div>
		</div>

	</div>
</template>

<script>
	import BaseMixin from '@/mixins/BaseMixin.vue'
	import gsLeft from "@/component/gs_left.vue";
	export default {
		mixins: [BaseMixin],
		components: {
			gsLeft
		},
		data() {
			return {
				checkIndex: '4-4',
				tableData: [],
				currentPage: 1,
				total: 1,
				formInline: {
					companyName: "", // 搜索字段
					orderNum: "",
					status: 0,
					page: 0, // 当前页
					size: 10,
				},
				showSub: false,
				subscription: true,
				subform: {}
			}
		},
		methods: {
			initData(formInline) {
				this.subform = {
					id: this.vuex_user.id,
					nickName: this.vuex_user.nickName,
					avatar: this.vuex_user.avatar,
					gender: this.vuex_user.gender,
					openId: this.vuex_user.openId,
					wxOpenid: this.vuex_user.wxOpenid,
					phone: this.vuex_user.phone,
					password: this.vuex_user.password,
					name: this.vuex_user.name,
					simpleName: this.vuex_user.simpleName,
					province: this.vuex_user.province,
					city: this.vuex_user.city,
					district: this.vuex_user.district,
					address: this.vuex_user.address,
					postalCode: this.vuex_user.postalCode,
					website: this.vuex_user.website,
					intro: this.vuex_user.intro,
					subscribe: this.vuex_user.subscribe,
					isPass: this.vuex_user.isPass,
					openAgency: this.vuex_user.openAgency,
					createTime: this.vuex_user.createTime,
					autid: this.vuex_user.autid
				}
				this.subscription = this.subform.subscribe
				this.$u.api.collectList(formInline).then(res => {
					this.tableData = res.data.content
					this.total = res.data.totalElements

				})
			},
			collected(index, row) {
				this.$u.api.resumeCollect(row.resume.id + '').then(resp => {
					this.uni.showToast('取消收藏成功')
					// this.tableData.splie(index, 1)
					if (this.tableData.length < 10) {
						this.formInline.page = 0
						this.initData(this.formInline)
					}
				})
			},
			editSub() {
				if (this.subscription) {
					this.$u.api.updateSubscribe(this.subscription + '').then(res => {
						this.uni.showToast('订阅开启成功')
						this.subform.subscribe = true
						this.$u.vuex('vuex_user', this.subform)
						this.showSub = false

					})
				} else {
					this.$u.api.updateSubscribe(this.subscription + '').then(res => {
						this.uni.showToast('订阅关闭成功')
						this.subform.subscribe = false
						this.$u.vuex('vuex_user', this.subform)
						this.showSub = false
					})
				}
			},
			handleEdit(index, row) {},
			handleSizeChange(val) {
				this.formInline.size = val
				this.initData(this.formInline)
			},
			handleCurrentChange(val) {
				this.formInline.page = val - 1
				this.initData(this.formInline)
			},
		}
	}
</script>
<style>
	page {
		background: none;
	}

	.el-submenu .el-menu-item {
		min-width: 100%;
	}
</style>
<style lang="less" scoped>
	.enterprise {
		margin: 0 auto;
		width: 68.5vw;
		/* 边距 */
		padding-top: 50px;
		background: #fff;
		box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);

		.menuContent {
			background: #fff;
			padding: 0.5vw 2.083vw 1.5625vw;
		}
	}

	.el-pagination {
		margin-top: 0.833vw;
		text-align: right;
	}
</style>