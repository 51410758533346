<template>
  <div>
    <el-col :span="4">
      <el-menu
        :default-active="checkIndex"
        @select="check"
        class="el-menu-vertical-demo"
        @click="check"
      >
        <el-menu-item index="1">
          <span slot="title">账号管理</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span slot="title">刷新置顶</span>
        </el-menu-item>
        <el-submenu index="3">
          <template slot="title">
            <span>我的简历</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="3-1">简历管理</el-menu-item>
            <el-menu-item index="3-2">投递记录</el-menu-item>
            <el-menu-item index="3-3">收藏企业</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="8-1">
          <span slot="title">招聘浏览记录</span>
        </el-menu-item>
        <el-menu-item index="9">
          <span slot="title">企业面试通知</span>
        </el-menu-item>
        <el-menu-item index="4">
          <span slot="title">中介担保</span>
        </el-menu-item>
        <!-- <el-menu-item index="5">
				<span slot="title">培训订单</span>
			</el-menu-item>
			<el-menu-item index="6">
				<span slot="title">证件办理</span>
			</el-menu-item> -->
        <el-menu-item index="7">
          <span slot="title">我的订阅</span>
        </el-menu-item>
      </el-menu>
    </el-col>
    <el-dialog title="提示" :visible.sync="showSub" width="30%">
      <span v-if="userForm.subscribe" style="margin-right: 20px">是否关闭订阅</span>
      <span v-else style="margin-right: 20px">是否开启订阅</span>
      <el-radio v-model="subscription" :label="true">是</el-radio>
      <el-radio v-model="subscription" :label="false">否</el-radio>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSub = false">取 消</el-button>
        <el-button type="primary" @click="editSub">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/BaseMixin.vue";
export default {
  mixins: [BaseMixin],
  props: {
    userFormW: Object,
  },
  data() {
    return {
      checkIndex: this.uni.getStorageSync("checkIndex") || "1",
      showSub: false,
      subscription: true,
      userForm: {
        subscribe: false,
      },
    };
  },
  components: {},
  watch: {
    userFormW: {
      handler(newV) {
        console.log("newV", newV);
        if (newV) {
          this.userForm = newV;
          this.subscription = this.userForm.subscribe;
          console.log("this.subscription", this.subscription);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initData() {},
    editSub() {
      if (this.subscription) {
        this.userForm.subscribe = true;
        this.$u.api.updateInfo(this.userForm).then((res) => {
          this.$u.api.getInfo().then((resp) => {
            this.$u.vuex("vuex_user", resp.data);
            this.uni.showToast("订阅开启成功");
            this.showSub = false;
          });
        });
      } else {
        this.userForm.subscribe = false;
        this.$u.api.updateInfo(this.userForm).then((res) => {
          this.$u.api.getInfo().then((resp) => {
            this.$u.vuex("vuex_user", resp.data);
            this.uni.showToast("订阅关闭成功");
            this.showSub = false;
          });
        });
      }
    },
    check(index) {
      if (index == "1") {
        this.jump("account_manage");
      } else if (index == "2") {
        this.jump("buy_package");
      } else if (index == "3-1") {
        this.jump("resume_manage");
      } else if (index == "3-2") {
        this.jump("delivery_record");
      } else if (index == "3-3") {
        this.jump("collection_position");
      } else if (index == "4") {
        this.jump("personal_guarantee");
      } else if (index == "5") {
        this.jump("training_order");
      } else if (index == "6") {
        this.jump("document_handling");
      } else if (index == "7") {
        // this.jump('my_subscription')
        this.showSub = true;
      } else if (index == "8-1") {
        this.jump("qy_job_browse");
      } else if (index == "9") {
        this.jump("qy_interview_notice");
      }
      if (index != "7") {
        this.checkIndex = index;
        this.$u.vuex("checkIndex", this.checkIndex);
      }
      console.log(this.checkIndex);
    },
  },
};
</script>
<style>
page {
  background: #fafafa;
}
</style>
<style lang="less" scoped>
.personal {
  margin: 10px 18.75vw 6.25vw;

  .menuContent {
    background: #fff;
    padding: 1.5625vw 2.083vw;
  }
}
</style>
