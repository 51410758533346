<script>
export default {
    data() {
        return {
            cannotSendCode: true,
            codeText: '发送验证码',
            countdownTime: 60,
            smsApi: null
        }
    },
    methods: {
        sendCode(mobile, types, froms) {
            console.log(this)
            if (!mobile || mobile.length !== 11) {
                this.uni.showToast({
                    title: '手机号码必须是11位数字',
                })
                return
            }
            if (this.smsApi) {
                this.uni.showToast({
                    title: '正在获取验证码',
                })
                const data = {
                    from: !froms ? 'member' : 'company',
                    phone: mobile,
                    type: types
                }
                this.smsApi(data).then((res) => {
                    // this.$ui.hideLoading()
                    this.countDown()
                })
            }
        },
        countDown() {
            this.cannotSendCode = true
            setTimeout(() => {
                this.codeText = (--this.countdownTime + 's')
                if (this.countdownTime === 0) {
                    this.cannotSendCode = false
                    this.codeText = '发送验证码'
                    this.countdownTime = 60
                } else {
                    this.countDown()
                }
            }, 1000)
        }
    }
}
</script>
