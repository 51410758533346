// 强制登录，onLoad时当前页面出栈，不返回当前页
export function forceLogin(vuex_token) {
  if (!vuex_token) {
    window.location.href = 'login.html'
  }
}

// 校验登录，调用方法时当前页面不出栈，返回当前页
export function toLogin(vuex_token) {
  if (!vuex_token) {
    window.location.href = 'login.html'
  }
}

export function getCurrentRoute() {
  const routes = getCurrentPages() // 获取当前打开过的页面路由数组
  const curRoute = routes[routes.length - 1].route // 获取当前页面路由
  const curParam = routes[routes.length - 1].options // 获取路由参数
  // 拼接参数
  let param = ''
  for (const key in curParam) {
    param += '&' + key + '=' + curParam[key]
  }
  return curRoute + param.replace('&', '?')
}
export function formatDate(time, fmt = 'yyyy-MM-dd hh:mm') {
  if (!time) {
    return ''
  }
  const date = new Date(time)
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
    }
  }
  return fmt
}

export function openURL(url) {
  window.open(url)
}
export function random(min, max) {
  if (min >= 0 && max > 0 && max >= min) {
    const gab = max - min + 1
    return Math.floor(Math.random() * gab + min)
  } else {
    return 0
  }
}
export function addNum(num1, num2) {
  var sq1, sq2, m;
  try {
    sq1 = num1.toString().split(".")[1].length;
  }
  catch (e) {
    sq1 = 0;
  }
  try {
    sq2 = num2.toString().split(".")[1].length;
  }
  catch (e) {
    sq2 = 0;
  }
  m = Math.pow(10, Math.max(sq1, sq2));
  return (num1 * m + num2 * m) / m;
}
export function routeUrl(url) {
  console.log(url)
  let routes = getCurrentPages(); // 获取当前打开过的页面路由数组
  console.log(routes)
  let curRoute = routes[routes.length - 1].route
  console.log(curRoute)
  return 1 
}
export default { forceLogin, toLogin, openURL, random, formatDate, addNum, routeUrl }
