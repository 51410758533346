<template>
  <div>
		<div v-if="tableData.length==0" class="noRegistration">
			<div>您的企业还没有办证信息，请先去发布</div>
			<el-button type="primary">新增办证</el-button>
			</div>
			<div v-else>
		<div class="trainInfo">
			<div class="trainInfo-title">办证信息</div>
			<el-button type="default">新增办证</el-button>
		</div>
		<el-form ref="form" :model="form" label-width="100px">
			<el-form-item label="发布日期：">
				<el-button type="text" @click="checkStatus">不限</el-button>
				<el-col :span="11">
					<el-date-picker
					      v-model="form.date"
					      type="date"
					      placeholder="选择日期">
					    </el-date-picker>
				</el-col>
			</el-form-item>
			<el-form-item label="订单状态：">
				<el-button type="text" @click="checkStatus">全部</el-button>
				<el-button type="text">上架中</el-button>
				<el-button type="text">已下架</el-button>
			</el-form-item>
			<el-form-item label="办证搜索：" class="enterprise-search">
				<el-input placeholder="输入证件名称" v-model="form.search" class="input-with-select">
					
				</el-input>
				<el-button ref="searchBtn" :type="form.search ? 'primary' : 'info'">搜索</el-button>
			</el-form-item>
		</el-form>
		<el-table
			border
			:data="tableData"
			style="width: 100%"
			:header-cell-style="{
				'background-color': 'rgba(32, 137, 255, 0.07)',
				color: '#333',
				'font-size': '0.833vw',
				'font-weight': '400'
			}"
		>
		<el-table-column
		     type="selection"
		     width="55">
		   </el-table-column>
			<el-table-column prop="certificateName" label="证件名称"></el-table-column>
			<el-table-column prop="phone" label="联系电话"></el-table-column>
			<el-table-column prop="oldPrice" label="原价格" width="80"></el-table-column>
			<el-table-column prop="newPrice" label="现价格" width="80"></el-table-column>
			<el-table-column prop="releaseDate" label="发布日期"></el-table-column>
			<el-table-column prop="status" label="状态" width="80"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="text" @click="handleEdit(scope.$index, scope.row)">查看</el-button>
					<el-button type="text" @click="handleEdit(scope.$index, scope.row)">下架</el-button>
					<el-button type="text" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="tableBottom">
			<div>
				<el-button type="text" @click="handleEdit(scope.$index, scope.row)">批量上架</el-button>
				<el-button type="text" @click="handleEdit(scope.$index, scope.row)">批量下架</el-button>
				<el-button type="text" @click="handleEdit(scope.$index, scope.row)">批量删除</el-button>
			</div>
			<div style="text-align:right;"><el-pagination layout="prev, pager, next, jumper" :total="100"></el-pagination></div>
		</div>
		</div>
  </div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin.vue'
export default {
  mixins: [BaseMixin],
  data() {
    return {
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			},
			form: {
				date: '',
				employJob: '选项1',
				search: ''
			},
			options: [
				{
					value: '选项1',
					label: '所有职务'
				},
				{
					value: '选项2',
					label: '机工'
				}
			],
			tableData: [
				// {
				// 	certificateName: '对讲新培邻书书',
				// 	phone: '18288888888',
				// 	oldPrice: '600',
				// 	newPrice: '500',
				// 	releaseDate: '2021-06-20',
				// 	status: '上架中'
				// },
				// {
				// 	certificateName: '对讲新培邻书书',
				// 	phone: '18288888888',
				// 	oldPrice: '600',
				// 	newPrice: '500',
				// 	releaseDate: '2021-06-20',
				// 	status: '上架中'
				// },
				// {
				// 	certificateName: '对讲新培邻书书',
				// 	phone: '18288888888',
				// 	oldPrice: '600',
				// 	newPrice: '500',
				// 	releaseDate: '2021-06-20',
				// 	status: '上架中'
				// },
				// {
				// 	certificateName: '对讲新培邻书书',
				// 	phone: '18288888888',
				// 	oldPrice: '600',
				// 	newPrice: '500',
				// 	releaseDate: '2021-06-20',
				// 	status: '已下架'
				// },
				// {
				// 	certificateName: '对讲新培邻书书',
				// 	phone: '18288888888',
				// 	oldPrice: '600',
				// 	newPrice: '500',
				// 	releaseDate: '2021-06-20',
				// 	status: '已下架'
				// }
			],
			select: '1'
    }
  },
  methods: {
    initData() {
    },
		onSubmit() {
			console.log('submit!');
		},

		handleEdit(index, row) {
		},
		checkStatus(e) {
		}
  }
}
</script>
<style>
page{
  background:#fafafa;
}
.enterprise-search >>> .el-form-item__content {
	display: flex;
}
</style>
<style lang="less" scoped>
	.noRegistration {
		// position: absolute;
		padding: 11.979vw;
		text-align: center;
		// left: 360px;
		div {
			font-size: 0.833vw;
		}
		button {
			font-size: 0.833vw;
			margin-top: 3.6458vw;
			width: 6.25vw;
		}
	}
	.trainInfo {
		// width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #e5e5e5;
		padding-bottom: 20px;
		.trainInfo-title {
			font-size: 1.0416vw;
		}
		button {
			margin: 0;
			width: 100px;
			background-color: #2089ff;
			font-size: 14px;
			color: #ffffff;
		}
	}
	
	.el-form {
		margin-top: 2.604vw;
		.el-input {
			width: 15.625vw;
			margin-right: 0.52vw;
		}
	}
	.el-table {
		margin-top: 2.083vw;
	}
	.tableBottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.833vw 0 6.25vw;
	}
</style>
