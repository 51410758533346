<template>
  <div>
		<div class="trainInfo">
			<div class="trainInfo-title">办证订单</div>
		</div>
		<el-form ref="form" :model="form" label-width="4.167vw">
			<el-form-item label="创建时间">
				<el-col :span="11">
					<el-date-picker
						v-model="form.date"
						type="daterange"
						align="right"
						unlink-panels
						clearable
						range-separator="-"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						:picker-options="pickerOptions"
					></el-date-picker>
				</el-col>
			</el-form-item>
			<el-form-item label="订单状态">
				<el-button type="text" @click="checkStatus">全部16825</el-button>
				<el-button type="text">完结10000</el-button>
				<el-button type="text">待支付6000</el-button>
				<el-button type="text">已过期800</el-button>
				<el-button type="text">办理中20</el-button>
				<el-button type="text">已退款5</el-button>
				<el-button type="text">申请退款0</el-button>
			</el-form-item>
			<el-form-item label="订单搜索" class="enterprise-search">
				<el-input v-model="form.search" class="input-with-select">
					<el-select v-model="select" slot="prepend" placeholder="请选择">
						<el-option label="课程标题" value="1"></el-option>
						<el-option label="订单号" value="2"></el-option>
						<el-option label="用户电话" value="3"></el-option>
					</el-select>
				</el-input>
				<el-button ref="searchBtn" :type="form.search ? 'primary' : 'info'">搜索</el-button>
			</el-form-item>
		</el-form>
		<el-table
			border
			:data="tableData"
			style="width: 100%"
			:span-method="arraySpanMethod"
			:header-cell-style="{
				'background-color': 'rgba(32, 137, 255, 0.07)',
				color: '#333',
				'font-size': '0.833vw',
				'font-weight': '400'
			}"
		>
			<el-table-column prop="orderID" label="订单ID" width="80"></el-table-column>
			<el-table-column prop="orderNum" label="订单号"></el-table-column>
			<el-table-column prop="courseTitle" label="证件名称"></el-table-column>
			<el-table-column prop="bearCost" label="支付费用" width="100"></el-table-column>
			<el-table-column prop="user" label="用户" width="80"></el-table-column>
			<el-table-column prop="creationTime" label="创建时间"></el-table-column>
			<el-table-column prop="status" label="状态" width="80"></el-table-column>
			<el-table-column label="操作" width="60">
				<template slot-scope="scope">
					<el-button type="text" @click="handleEdit(scope.$index, scope.row)">详情</el-button>
				</template>
			</el-table-column>
			
		</el-table>
		<div class="tableBottom">
			
				<div>总额: ￥20,000,000</div>
			<div style="text-align:right;"><el-pagination layout="prev, pager, next, jumper" :total="100"></el-pagination></div>
		</div>
  </div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin.vue'
export default {
  mixins: [BaseMixin],
  data() {
    return {
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			},
			form: {
				date: '',
				employJob: '选项1',
				search: ''
			},
			options: [
				{
					value: '选项1',
					label: '所有职务'
				},
				{
					value: '选项2',
					label: '机工'
				}
			],
			tableData: [
				{
					orderID: '24',
					orderNum: '20210624054215',
					courseTitle: '大副培训',
					bearCost: '500',
					user: '风沙燕',
					creationTime: '2021-06-20 10:12:24',
					status: '已支付'
				},
				{
					orderID: '24',
					orderNum: '20210624054215',
					courseTitle: '大副培训',
					bearCost: '500',
					user: '风沙燕',
					creationTime: '2021-06-20 10:12:24',
					status: '已支付'
				},
				{
					orderID: '24',
					orderNum: '20210624054215',
					courseTitle: '大副培训',
					bearCost: '500',
					user: '风沙燕',
					creationTime: '2021-06-20 10:12:24',
					status: '已支付'
				},
				{
					orderID: '24',
					orderNum: '20210624054215',
					courseTitle: '大副培训',
					bearCost: '500',
					user: '风沙燕',
					creationTime: '2021-06-20 10:12:24',
					status: '已支付'
				},
				{
					orderID: '24',
					orderNum: '20210624054215',
					courseTitle: '大副培训',
					bearCost: '500',
					user: '风沙燕',
					creationTime: '2021-06-20 10:12:24',
					status: '已支付'
				},
				{
					orderID: '24',
					orderNum: '20210624054215',
					courseTitle: '大副培训',
					bearCost: '500',
					user: '风沙燕',
					creationTime: '2021-06-20 10:12:24',
					status: '已支付'
				},
				{
					orderID: '24',
					orderNum: '20210624054215',
					courseTitle: '大副培训',
					bearCost: '500',
					user: '风沙燕',
					creationTime: '2021-06-20 10:12:24',
					status: '已支付'
				}
			],
			select: '1'
    }
  },
  methods: {
    initData() {
    },
		onSubmit() {
			console.log('submit!');
		},

		handleEdit(index, row) {
		},
		checkStatus(e) {
		},
		arraySpanMethod({ row, column, rowIndex, columnIndex }) {
			if(rowIndex == '8') {
			}
			// console.log(row, column, rowIndex, columnIndex)
		}
  }
}
</script>
<style>
page{
  background:#fafafa;
}
.enterprise-search >>> .el-form-item__content {
	display: flex;
}
.el-input >>> .el-input-group__prepend {
	width: 70px;
	background-color: #fff;
}
</style>
<style lang="less" scoped>
	.trainInfo {
		// width: 100%;
		border-bottom: 1px solid #e5e5e5;
		padding-bottom: 20px;
		.trainInfo-title {
			font-size: 1.0416vw;
		}
		button {
			margin: 0;
			width: 100px;
			background-color: #2089ff;
			font-size: 14px;
			color: #ffffff;
		}
	}
	
	.el-form {
		margin-top: 2.604vw;
		.el-input {
			width: 15.625vw;
			margin-right: 0.52vw;
		}
	}
	.el-table {
		margin-top: 2.083vw;
	}
	.tableBottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.833vw 0 6.25vw;
	}
</style>

