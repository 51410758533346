<template>
  <!-- 返回顶部 -->
  <aside class="sidebar">
    <div class="sidebar-item official-account">
      <img class="icon-img" src="@/static/kefu.png" alt="在线客服" />
      <div>在线客服</div>
      <img :src="kefuUrl" class="hover-img" alt="万洋船员管家公众号" />
    </div>
    <div class="sidebar-item official-account">
      <img class="icon-img" src="@/static/phone.png" alt="拨打电话" />
      <div>拨打电话</div>
      <span class="hover-img" style="line-height:13vw;font-size:20px;">16750591566</span>
      <!-- <img :src="gzhUrl" class="hover-img" alt="拨打电话" /> -->
    </div>
    <div class="sidebar-item official-account">
      <img class="icon-img" src="@/static/erweima-gzh.png" alt="万洋船员管家公众号" />
      <div>公众号</div>
      <img :src="gzhUrl" class="hover-img" alt="万洋船员管家公众号" />
    </div>
    <div class="sidebar-item official-account">
      <img class="icon-img" src="@/static/xcx-icon.png" alt="小程序" />
      <div>小程序</div>
      <img src="@/static/xcx-ma.jpg" class="hover-img" alt="小程序" />
    </div>
    <div class="sidebar-item" @click="backTop">
      <img class="icon-img" src="@/static/go-top.png" alt="返回顶部" />
      <div>返回顶部</div>
    </div>
  </aside>
</template>
<script>
export default {
  data() {
    return {
      // 公众号图片
      gzhUrl: "",
      //客服图片
      kefuUrl: "",
    };
  },
  mounted() {
    this.$u.api.configSym({ keyStr: "advertisingLeft,advertisingRight" }).then((res) => {
      this.kefuUrl = res.data.advertisingLeft.split(",")[0];
      this.gzhUrl = res.data.advertisingRight.split(",")[0];
      // this.$u.vuex('advertiseList', { right: res.data.advertisingRight.split(','), left: res.data.advertisingLeft.split(',') });
    });
  },
  methods: {
    backTop() {
      // this.getTop();
      // window.location.href = '#top';
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.search-box {
  margin-top: 6px;
  width: 100%;
}

.search-box input {
  padding: 0 15px;
  width: 100%;
  height: 32px;
  line-height: 32px;
  color: #606266;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.search-box input:focus {
  border-color: #409eff;
  outline: 0;
}

.search-box input::-webkit-input-placeholder {
  color: #c0c4cc;
}

.tip-box {
  width: 100%;
  max-height: 280px;
  position: absolute;
  top: 72px;
  z-index: 10000;
  overflow-y: auto;
  background-color: #fff;
}
</style>
<style lang="scss" scoped>
// 返回顶部
.sidebar {
  position: fixed;
  z-index: 88;
  right: 0;
  bottom: 23%;
  // padding: 14px 12px 0;
  box-shadow: 0 2px 20px 5px #eee;
  border-radius: 10px 0 0 10px;
  background-color: #fff;
  font-size: 0.8vw;
  text-align: center;

  .hover-img {
    position: absolute;
    top: 50%;
    right: 125%;
    transform: translate(0, -50%);
    width: 10vw;
    height: 13vw;
    display: none;
  }

  .official-account {
    position: relative;
  }

  // 公众号
  .official-account:hover {
    .hover-img {
      display: block;
    }
  }

  .sidebar-item {
    margin: 0.5vw;
    padding: 0.5vw 0;
    border-bottom: 1px solid #eee;
    cursor: pointer;

    .icon-img {
      width: 2vw;
      height: 2vw;
    }
  }
}
</style>
