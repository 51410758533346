<template>
	<div>
		<!-- <div class="advertisement" @click="adverJump('right')">
			<img :src="advertiseList.right[0]" mode="" />
		</div> -->
		<!-- <div class="advertisement2" @click="adverJump('left')"><img :src="advertiseList.left[0]" alt="" /></div> -->
	</div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin.vue';
export default {
	mixins: [BaseMixin],
	data() {
		return {
			// colseAdve: true
			pwd: '',
			visitUserId: ''
		};
	},
	mounted() {
		// if (this.advertiseList.left.length === 0) {
		// 	this.$u.api.configSym({ keyStr: 'advertisingLeft,advertisingRight' }).then(res => {
		// 		this.$u.vuex('advertiseList', { right: res.data.advertisingRight.split(','), left: res.data.advertisingLeft.split(',') });
		// 	});
		// }
		// let now = new Date().getTime();
		// if (this.visitTimeStamp) {
		// 	this.getTime(now, this.visitTimeStamp);
		// } else {
		// 	this.randomString();
		// 	this.$u.vuex('visitTimeStamp', now);
		// }
		// let type = 0;
		// if (this.member_token) {
		// 	// 用户登录
		// 	type = 1;
		// } else if (this.vuex_token) {
		// 	// 公司登录
		// 	type = 2;
		// } else {
		// 	type = 0;
		// }
		// this.$u.vuex('visitCode', this.pwd + '_PC_' + this.visitUserId + '_' + type);
		// this.randomString()
	},
	methods: {
		initData() {
			// this.$u.vuex('advertiseList',{right:'231',left: '8908'})
		},
		getTime(now, old) {
			// let now = new Date().getTime()
			let type = 0;
			if (this.member_token) {
				// 用户登录
				type = 1;
			} else if (this.vuex_token) {
				// 公司登录
				type = 2;
			} else {
				type = 0;
			}
			this.visitUserId = new Date().getTime();
			// console.log('获取设备后');
			if (this.vuex_user.id) {
				this.visitUserId = this.vuex_user.id;
			}
			this.randomString();
			this.$u.vuex('visitCode', this.pwd + '_PC_' + this.visitUserId + '_' + type);
		},
		randomString() {
			let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
			let maxPos = $chars.length;
			let pwd = '';
			for (let i = 0; i < 18; i++) {
				pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
			}
			this.pwd = pwd;
		},
		adverJump(direction) {
			if (direction == 'right') {
				window.location.href = this.advertiseList.right[1];
			} else if (direction == 'left') {
				window.location.href = this.advertiseList.left[1];
			}
		}
	}
};
</script>
<style>
page {
	background: #fafafa;
}
</style>
<style lang="less" scoped>
.advertisement {
	height: 240px;
	width: 180px;
	top: 35%;
	bottom: 0;
	left: 0;
	position: fixed;
	background-color: #fff;

	img {
		height: 240px;
		width: 180px;
	}

	.close {
		font-size: 18px;
		top: 6px;
		right: 6px;
		position: absolute;
	}
}

.advertisement2 {
	height: 240px;
	width: 180px;
	top: 35%;
	bottom: 0;
	right: 0;
	position: fixed;
	background-color: #fff;

	img {
		height: 240px;
		width: 180px;
	}
}
</style>
