import {
  Toast,
  Dialog
} from 'vant';
export function showToast(msg) {
  // console.log('提示信息', msg);
  Toast(msg.title || msg || '提示')
}

export function pageScrollTo(info) {}
export function getStorageSync(key) {
  let val = '';
  try {
    val = JSON.parse(localStorage.getItem(key));
  } catch (error) {}
  return val;
}
export function removeStorageSync(key) {
  localStorage.removeItem(key)
}

export function showModal(config = {}) {
  Dialog.confirm({
    title: config.title || '温馨提示',
    message: config.content || '提示内容'
  }).then(() => {
    config.success && config.success({
      confirm: true
    })
  }).catch(err => {
    config.fail && config.fail(err);
  })
}

export default {
  showToast,
  pageScrollTo,
  getStorageSync,
  removeStorageSync,
  showModal
}