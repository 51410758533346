<template>
	<div>
		    <div class="enterprise">
		<div style="background-color: #fff;">
		<el-row>
          <gsLeft :userFormW="subform"></gsLeft>
			<el-col :span="20">
				<div class="menuContent">
          
		<div style="font-size: 20px;">培训学校信息</div>
		<el-form ref="form" :rules="rules" :model="form" label-width="100px">
			<el-form-item label="学校名称：" prop="name"><el-input v-model="form.name"></el-input></el-form-item>
			<el-form-item label="学校图标：" prop="icon">
				<div>支持上传jpg/png文件，大小不超过10M</div>
				<el-upload
					:action="action"
					list-type="picture-card"
					:class="uploadDisabled ? 'disabled': ''"
					:limit="1"
					ref="upload"
					:on-change="iconChange"
					:on-preview="iconPreview"
					:on-remove="iconRemove"
					:on-success="iconSuccess"
					:file-list="fileList"
					accept=".jpg, .png"
				>
				
					<i class="el-icon-plus"></i>
				</el-upload>
				
				<!-- 预览图片 -->
				<el-dialog :visible.sync="iconShow" append-to-body><img width="100%" :src="iconUrl" alt="" /></el-dialog>
				
								<!-- <el-upload
  class="avatar-uploader"
  action="action"
  :show-file-list="false"
  :on-success="handleAvatarSuccess"
  :before-upload="beforeAvatarUpload"> -->
  <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> v-else -->
<!-- 	
  <i class="el-icon-plus"></i>
</el-upload>
<el-dialog :visible.sync="dialogVisibleimg" append-to-body><img width="100%" :src="imageUrl" alt="" /></el-dialog> -->

					
			</el-form-item>

			<el-form-item label="联系电话" prop="name"><el-input v-model="form.phone"></el-input></el-form-item>
			<el-form-item label="学校地址">
				<el-cascader
														size="large"
														:options="addrOptions"
														v-model="selectedOptions"
														@change="addrChange"
													>
													</el-cascader>
			</el-form-item>
			<!-- <el-form-item label="详细地址" class="enterprise-search"><el-input v-model="form."></el-input></el-form-item> -->
			<el-form-item label="经度" class="enterprise-search"><el-input v-model="form.lon"></el-input></el-form-item>
			<el-form-item label="纬度" class="enterprise-search"><el-input v-model="form.lat"></el-input></el-form-item>
			<el-form-item label="学校图片：">
				<!-- fileType:3 图片 -->
				<div>支持上传jpg/png文件，大小不超过10M</div>
				<el-upload
                :file-list="imgs"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview1"
                  :on-remove="handleRemove"
                  :limit="20"
                  :on-success="handleImageSuccess"
									:action="action"
                >
                <i class="el-icon-plus"></i>
                </el-upload>
				<!-- 
                  
                  :headers="headers"
                  :data="dataObj"action="https://jsonplaceholder.typicode.com/posts/"<el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
					<i class="el-icon-plus"></i>
				</el-upload> -->
				<el-dialog :visible.sync="dialogVisible"><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog>
			</el-form-item>
			<el-form-item label="学校简介：" prop="intro">
				<div style="border: 1px solid #E0E0E0;" class="container">
					<div class="page-body">
						<div class="wrapper">
							<div class="toolbar" @tap="format" style="height: 40px;overflow-y: auto;">
								<div :class="formats.bold ? 'ql-active' : ''" class="iconfont icon-zitijiacu" data-name="bold"></div>
								<div :class="formats.italic ? 'ql-active' : ''" class="iconfont icon-zitixieti" data-name="italic"></div>
								<div :class="formats.underline ? 'ql-active' : ''" class="iconfont icon-zitixiahuaxian" data-name="underline"></div>
								<div :class="formats.strike ? 'ql-active' : ''" class="iconfont icon-zitishanchuxian" data-name="strike"></div>
								<div :class="formats.align === 'left' ? 'ql-active' : ''" class="iconfont icon-zuoduiqi" data-name="align" data-value="left"></div>
								<div :class="formats.align === 'center' ? 'ql-active' : ''" class="iconfont icon-juzhongduiqi" data-name="align" data-value="center"></div>
								<div :class="formats.align === 'right' ? 'ql-active' : ''" class="iconfont icon-youduiqi" data-name="align" data-value="right"></div>
								<div :class="formats.align === 'justify' ? 'ql-active' : ''" class="iconfont icon-zuoyouduiqi" data-name="align" data-value="justify"></div>
								<div :class="formats.lineHeight ? 'ql-active' : ''" class="iconfont icon-line-height" data-name="lineHeight" data-value="2"></div>
								<div :class="formats.letterSpacing ? 'ql-active' : ''" class="iconfont icon-Character-Spacing" data-name="letterSpacing" data-value="2em"></div>
								<div :class="formats.marginTop ? 'ql-active' : ''" class="iconfont icon-722bianjiqi_duanqianju" data-name="marginTop" data-value="20px"></div>
								<div :class="formats.previewarginBottom ? 'ql-active' : ''" class="iconfont icon-723bianjiqi_duanhouju" data-name="marginBottom" data-value="20px"></div>
								<div class="iconfont icon-clearedformat" @tap="removeFormat"></div>
								<div :class="formats.fontFamily ? 'ql-active' : ''" class="iconfont icon-font" data-name="fontFamily" data-value="Pacifico"></div>
								<div :class="formats.fontSize === '24px' ? 'ql-active' : ''" class="iconfont icon-fontsize" data-name="fontSize" data-value="24px"></div>

								<div :class="formats.color === '#0000ff' ? 'ql-active' : ''" class="iconfont icon-text_color" data-name="color" data-value="#0000ff"></div>
								<div :class="formats.backgroundColor === '#00ff00' ? 'ql-active' : ''" class="iconfont icon-fontbgcolor" data-name="backgroundColor" data-value="#00ff00"></div>

								<div class="iconfont icon-date" @tap="insertDate"></div>
								<div class="iconfont icon--checklist" data-name="list" data-value="check"></div>
								<div :class="formats.list === 'ordered' ? 'ql-active' : ''" class="iconfont icon-youxupailie" data-name="list" data-value="ordered"></div>
								<div :class="formats.list === 'bullet' ? 'ql-active' : ''" class="iconfont icon-wuxupailie" data-name="list" data-value="bullet"></div>
								<div class="iconfont icon-undo" @tap="undo"></div>
								<div class="iconfont icon-redo" @tap="redo"></div>

								<div class="iconfont icon-outdent" data-name="indent" data-value="-1"></div>
								<div class="iconfont icon-indent" data-name="indent" data-value="+1"></div>
								<div class="iconfont icon-fengexian" @tap="insertDivider"></div>
								<div class="iconfont icon-charutupian" @tap="insertImage"></div>
								<div :class="formats.header === 1 ? 'ql-active' : ''" class="iconfont icon-format-header-1" data-name="header" :data-value="1"></div>
								<div :class="formats.script === 'sub' ? 'ql-active' : ''" class="iconfont icon-zitixiabiao" data-name="script" data-value="sub"></div>
								<div :class="formats.script === 'super' ? 'ql-active' : ''" class="iconfont icon-zitishangbiao" data-name="script" data-value="super"></div>
								<div class="iconfont icon-shanchu" @tap="clear"></div>
								<div :class="formats.direction === 'rtl' ? 'ql-active' : ''" class="iconfont icon-direction-rtl" data-name="direction" data-value="rtl"></div>
							</div>

							<div class="editor-wrapper">
								<editor
									id="editor"
									class="ql-container"
									showImgSize
									showImgToolbar
									showImgResize
									v-html="form.intro"
									@statuschange="onStatusChange"
									:read-only="readOnly"
									@ready="onEditorReady"
									@blur="introBlur"
								></editor>
							</div>
						</div>
					</div>
				</div>
			</el-form-item>
			<el-form-item label="详细介绍：">
				<div style="border: 1px solid #E0E0E0;" class="container">
						<div class="page-body">
							<div class="wrapper">
								<div class="toolbar" @tap="format" style="height: 40px;overflow-y: auto;">
									<div :class="formats.bold ? 'ql-active' : ''" class="iconfont icon-zitijiacu" data-name="bold"></div>
									<div :class="formats.italic ? 'ql-active' : ''" class="iconfont icon-zitixieti" data-name="italic"></div>
									<div :class="formats.underline ? 'ql-active' : ''" class="iconfont icon-zitixiahuaxian" data-name="underline"></div>
									<div :class="formats.strike ? 'ql-active' : ''" class="iconfont icon-zitishanchuxian" data-name="strike"></div>
									<div :class="formats.align === 'left' ? 'ql-active' : ''" class="iconfont icon-zuoduiqi" data-name="align" data-value="left"></div>
									<div :class="formats.align === 'center' ? 'ql-active' : ''" class="iconfont icon-juzhongduiqi" data-name="align" data-value="center"></div>
									<div :class="formats.align === 'right' ? 'ql-active' : ''" class="iconfont icon-youduiqi" data-name="align" data-value="right"></div>
									<div :class="formats.align === 'justify' ? 'ql-active' : ''" class="iconfont icon-zuoyouduiqi" data-name="align" data-value="justify"></div>
									<div :class="formats.lineHeight ? 'ql-active' : ''" class="iconfont icon-line-height" data-name="lineHeight" data-value="2"></div>
									<div :class="formats.letterSpacing ? 'ql-active' : ''" class="iconfont icon-Character-Spacing" data-name="letterSpacing" data-value="2em"></div>
									<div :class="formats.marginTop ? 'ql-active' : ''" class="iconfont icon-722bianjiqi_duanqianju" data-name="marginTop" data-value="20px"></div>
									<div :class="formats.previewarginBottom ? 'ql-active' : ''" class="iconfont icon-723bianjiqi_duanhouju" data-name="marginBottom" data-value="20px"></div>
									<div class="iconfont icon-clearedformat" @tap="removeFormat"></div>
									<div :class="formats.fontFamily ? 'ql-active' : ''" class="iconfont icon-font" data-name="fontFamily" data-value="Pacifico"></div>
									<div :class="formats.fontSize === '24px' ? 'ql-active' : ''" class="iconfont icon-fontsize" data-name="fontSize" data-value="24px"></div>
				
									<div :class="formats.color === '#0000ff' ? 'ql-active' : ''" class="iconfont icon-text_color" data-name="color" data-value="#0000ff"></div>
									<div :class="formats.backgroundColor === '#00ff00' ? 'ql-active' : ''" class="iconfont icon-fontbgcolor" data-name="backgroundColor" data-value="#00ff00"></div>
				
									<div class="iconfont icon-date" @tap="insertDate"></div>
									<div class="iconfont icon--checklist" data-name="list" data-value="check"></div>
									<div :class="formats.list === 'ordered' ? 'ql-active' : ''" class="iconfont icon-youxupailie" data-name="list" data-value="ordered"></div>
									<div :class="formats.list === 'bullet' ? 'ql-active' : ''" class="iconfont icon-wuxupailie" data-name="list" data-value="bullet"></div>
									<div class="iconfont icon-undo" @tap="undo"></div>
									<div class="iconfont icon-redo" @tap="redo"></div>
				
									<div class="iconfont icon-outdent" data-name="indent" data-value="-1"></div>
									<div class="iconfont icon-indent" data-name="indent" data-value="+1"></div>
									<div class="iconfont icon-fengexian" @tap="insertDivider"></div>
									<div class="iconfont icon-charutupian" @tap="insertImage"></div>
									<div :class="formats.header === 1 ? 'ql-active' : ''" class="iconfont icon-format-header-1" data-name="header" :data-value="1"></div>
									<div :class="formats.script === 'sub' ? 'ql-active' : ''" class="iconfont icon-zitixiabiao" data-name="script" data-value="sub"></div>
									<div :class="formats.script === 'super' ? 'ql-active' : ''" class="iconfont icon-zitishangbiao" data-name="script" data-value="super"></div>
									<div class="iconfont icon-shanchu" @tap="clear"></div>
									<div :class="formats.direction === 'rtl' ? 'ql-active' : ''" class="iconfont icon-direction-rtl" data-name="direction" data-value="rtl"></div>
								</div>
				
								<div class="editor-wrapper">
									<editor
										id="editor"
										class="ql-container"
										showImgSize
										showImgToolbar
										showImgResize
										v-html="form.detail"
										@statuschange="onStatusChange"
										:read-only="readOnly"
										@ready="onEditorReady"
										@blur="detailBlur"
									></editor>
								</div>
							</div>
						</div>
					</div>
				
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm">{{this.form.id ? '修改' : '确定'}}</el-button>
			</el-form-item>
		</el-form>
		</div>
		<el-dialog
				title="提示"
				:visible.sync="showSub"
				width="30%">
				<span v-if="subform.subscribe" style="margin-right:20px">是否关闭订阅</span>
				<span v-else style="margin-right:20px">是否开启订阅</span>
				<el-radio v-model="subscription" :label="true">是</el-radio>
				<el-radio v-model="subscription" :label="false">否</el-radio>
				<span slot="footer" class="dialog-footer">
					<el-button @click="showSub = false">取 消</el-button>
					<el-button type="primary" @click="editSub">确 定</el-button>
				</span>
			</el-dialog>
			</el-col>
						
		</el-row>
		</div>
  </div>
	</div>
</template>

<script>
import { regionData,CodeToText,TextToCode } from 'element-china-area-data'

import adver from '@/pages/advertisement.vue';
import BaseMixin from '@/mixins/BaseMixin.vue';
import gsLeft from "@/component/gs_left.vue";

export default {
	mixins: [BaseMixin],
  components: { gsLeft },
	data() {
		return {
			checkIndex: '5-3',
			addrOptions: regionData,
      selectedOptions:[],
			action: 'http://192.168.0.115:8222/front/front/file/uploadFile',
			form: {
				imgs: ''
			},
			options: [
				{
					value: '选项1',
					label: '黄金糕'
				},
				{
					value: '选项2',
					label: '双皮奶'
				},
				{
					value: '选项3',
					label: '蚵仔煎'
				},
				{
					value: '选项4',
					label: '龙须面'
				},
				{
					value: '选项5',
					label: '北京烤鸭'
				}
			],
			rules: {},
			imgs: [],
			fileList: [],
			iconShow: false,
			iconUrl: '',
			dialogVisible: false,
			dialogImageUrl: '',
			imageUrl: '',
			addimg: [],
			removeimg: [],
			readOnly: false,
			formats: {},

      headers: {
        // Authorization: ''
      },
      dataObj: {
        folderId: 1
      },
			imgList: [],
			showSub: false,
			subscription: true,
			subform: {}
		};
	},
	computed: {
		uploadDisabled: function() {
			console.log(this.form.icon);
			return this.fileList.length > 0;
		}
	},
	methods: {
		initData() {
			this.subform = {
				id: this.vuex_user.id,
				nickName: this.vuex_user.nickName,
				avatar: this.vuex_user.avatar,
				gender: this.vuex_user.gender,
				openId: this.vuex_user.openId,
				wxOpenid: this.vuex_user.wxOpenid,
				phone: this.vuex_user.phone,
				password: this.vuex_user.password,
				name: this.vuex_user.name,
				simpleName: this.vuex_user.simpleName,
				province: this.vuex_user.province,
				city: this.vuex_user.city,
				district: this.vuex_user.district,
				address: this.vuex_user.address,
				postalCode: this.vuex_user.postalCode,
				website: this.vuex_user.website,
				intro: this.vuex_user.intro,
				subscribe: this.vuex_user.subscribe,
				isPass: this.vuex_user.isPass,
				openAgency: this.vuex_user.openAgency,
				createTime: this.vuex_user.createTime,
				autid: this.vuex_user.autid
			}
			this.subscription = this.subform.subscribe
			console.log(111,this.vuex_token);
			this.fileList = []
			this.imgs = []
			this.$u.api.school().then(res => {
				// this.fileList.push(res.data.icon)
				this.selectedOptions = [
                TextToCode[res.data.province].code,
                TextToCode[res.data.province][res.data.city].code,
								TextToCode[res.data.province][res.data.city][res.data.district].code
              ];
							// res.data.imgs = res.data.imgs
				this.fileList.push({url:res.data.icon})
				let imgs = res.data.imgs.split(',')
				for(let i in imgs) {
					let obj = {}
					obj.url = imgs[i]
					this.imgs.push(obj)
				}
				console.log();
				// this.imgs = res.data.imgs.split(',')
				this.form = res.data
				console.log(this.fileList);
				// console.log(this.imgs);
				console.log(res);
			})
		},
    handleRemoves(file, fileList) {
      console.log(this.payForm.evidence);
      delete this.payForm.evidence
    },
    uploadSuccess(fileList) {
      console.log(fileList)
      const url = fileList.data.url
      this.payForm.evidence = url
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, batchFileList) {
      this.$message.warning(`只能选择1个文件`)
    },
	handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.uni.showToast('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.uni.showToast('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
    addrChange() {
      var loc = "";
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += '-' + CodeToText[this.selectedOptions[i]];
      }
			this.form.province = loc.split('-')[1]
			this.form.city = loc.split('-')[2]
			this.form.district = loc.split('-')[3]
			console.log(this.selectedOptions);
      console.log(this.form);
			// this.selectedOptions = []
    },
		submitForm(formName) {
			// this.form.lat = 0
			// this.form.lon = 0
			this.form.imgs = this.form.imgs.slice(0,this.form.imgs.length - 1)
			// this.imgs = this.form.imgs.split(',')
			this.form.lat = Number(this.form.lat)
			this.form.lon = Number(this.form.lon)
				if(!this.form.id) {
					this.$u.api.editSchool(this.form).then(res => {
				this.uni.showToast({title:'添加企业培训学校成功',icon:'none'})
				
				console.log(res);
			this.initData()
			})
				}else {
				 delete	this.form.company
				 delete this.form.sort
				 delete this.form.isHot
				 this.$u.api.editSchool(this.form).then(res => {
				this.uni.showToast({title:'修改企业培训学校成功',icon:'none'})
				console.log(res);
			this.initData()
			})
				}
				console.log(this.form);
				console.log(this.fileList);
				console.log(this.imgs);
			
		},
		introBlur(e) {
			this.form.intro = e.detail.html
			console.log(e);
		},
		detailBlur(e) {
			this.form.detail = e.detail.html
			console.log(e);
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		// 添加图标
		iconChange(file, fileList) {
			console.log(this.fileList);
			this.fileList = fileList
			const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
			const isLt5M = file.size / 1024 / 1024 < 10;
			if (!isJPG) {
				this.uni.showToast('上传头像图片只能是 JPG 、png 格式!');
				fileList.splice(-1, 1); //移除错误文件
				return false;
			}
			if (!isLt5M) {
				this.uni.showToast('上传头像图片大小不能超过 10MB!');
				fileList.splice(-1, 1);
				return false;
			}
			console.log(file,fileList);
			// this.addimg = file.url;
			// this.form.icon = this.addimg;
		},
		// 删除图标
		iconRemove(file, fileList) {
			this.fileList = fileList
			console.log(fileList);
			this.form.icon = '';
			// this.formdata = new FormData();
		},
		// 图标预览
		iconPreview(file) {
			this.iconUrl = file.url;
			this.iconShow = true;
		},
		// 图标成功
		iconSuccess(response,file,fileList) {
			const that = this
			// console.log(response,file,fileList);
			this.uni.uploadFile({
				url: that.action,
				filePath: file.url,
				name: 'file',
				header: {
					'Token': "Company "+ that.vuex_token
				},
				formData: {
					fileType: 3
				},
				success: (uploadFileRes) => {
					let icon = JSON.parse(uploadFileRes.data)
					that.form.icon = icon.data
					console.log(uploadFileRes);
					console.log('123:',uploadFileRes.data);
				}
			})
		},
		// 图片成功
    handleImageSuccess(response, file, fileList) {
			console.log(response);
			console.log(file);
			const that = this
			this.uni.uploadFile({
            // url: 'http://192.168.0.115:8222/front/member/updateAvatar', //仅为示例，非真实的接口地址
            url: that.action, //仅为示例，非真实的接口地址
            filePath: file.url,
            name: 'file',
            header: {
							'Token': 'Company '+ that.vuex_token
            },
            formData: {
								fileType: 3
            },
            success: (uploadFileRes) => {
							let pic = JSON.parse(uploadFileRes.data)
							console.log();
							// let pic = uploadFileRes.data.split('\"')[5]
							
								that.form.imgs += pic.data + ','
							
							
							console.log(pic);
              console.log('123:',uploadFileRes.data);
            }
        })
				console.log(this.form);
				console.log(this.imgs);
				// this.form.imgs.slice(0,this.form.imgs.length - 1) = 
    },
    // 移除图片
    handleRemove(file,fileList) {
      let imgPath = ''
      imgPath = file.url
			let imgs = this.form.imgs.split(',')
			let a = imgs.filter(item => {
				return item !== imgPath
			})
			this.form.imgs = a.join(',')
      // this.form.imgs = this.form.imgs.filter(item => {
      //   if(item.response) {
      //     return item.response.data.url !== imgPath
      //   }else {
      //     return item.url !== imgPath
      //   }
      // })
    },
    handlePictureCardPreview1(file) {
      console.log(file);
      this.dialogImageUrl = file.imgPath
      this.dialogVisible = true
    },
		readOnlyChange() {
				this.readOnly = !this.readOnly
		},
		onEditorReady() {
		this.uni.createSelectorQuery().select('#editor').context((res) => {
			console.log(res);
			this.editorCtx = res.context
						}).exec()
		},
		undo() {
				this.editorCtx.undo()
			},
		redo() {
				this.editorCtx.redo()
			},
		format(e) {
						let {
							name,
							value
						} = e.target.dataset
						if (!name) return
						console.log(e)
						this.editorCtx.format(name, value)

		},
		onStatusChange(e) {
						const formats = e.detail
						this.formats = formats
					},
		insertDivider() {
						this.editorCtx.insertDivider({
							success: function() {
								console.log('insert divider success')
							}
						})
					},
		clear() {
						this.editorCtx.clear({
							success: function(res) {
								console.log("clear success")
							}
						})
		},
		removeFormat() {
						this.editorCtx.removeFormat()
					},
		insertDate() {
						const date = new Date()
						const formatDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
						this.editorCtx.insertText({
							text: formatDate
						})
		},
		insertImage() {
						this.uni.chooseImage({
							count: 1,
							success: (res) => {
								this.uni.uploadFile({
									// url: 'http://192.168.0.115:8222/front/member/updateAvatar', //仅为示例，非真实的接口地址
									url: this.action, //仅为示例，非真实的接口地址
									filePath: res.tempFilePaths[0],
									name: 'file',
									header: {
										'Token': 'Company '+ this.vuex_token
									},
									formData: {
											fileType: 3
									},
									success: (uploadFileRes) => {
											console.log('123:',uploadFileRes.data);
											console.log(res);
										this.editorCtx.insertImage({
											src: uploadFileRes.data,
											alt: '图像',
											success: function() {
												console.log('insert image success')
											}
										})
									}
								})
							}
						})
		},
		editSub() {
			if(this.subscription) {
				this.$u.api.updateSubscribe(this.subscription+'').then(res => {
					this.uni.showToast('订阅开启成功')
					this.subform.subscribe = true
					this.$u.vuex('vuex_user', this.subform)
					this.showSub = false
					console.log(res);
				})
			}else {
				this.$u.api.updateSubscribe(this.subscription+'').then(res => {
					this.uni.showToast('订阅关闭成功')
					this.subform.subscribe = false
					this.$u.vuex('vuex_user', this.subform)
					this.showSub = false
					console.log(res);
				})
			}
			
		},
				check(index) {
						this.checkIndex = index
						if(index == '1') {
							this.jump('enterprise_index')
						}else if(index == '2') {
							this.jump('ec_account_manage')
						}
						else if(index == '3') {
							this.jump('ec_buy_package')
						}
						else if(index == '4-1') {
							this.jump('post_recruitment_info')
						}
						else if(index == '4-2') {
							this.jump('manage_recruitment_info')
						}
						else if(index == '4-3') {
							this.jump('resume_delivery_received')
						}
						else if(index == '4-4') {
							this.jump('collected_resume')
						}
						else if(index == '5-1') {
							this.jump('ec_training_info')
						}
						else if(index == '5') {
							this.jump('ec_training_order')
						}
						else if(index == '7') {
							this.jump('corporate_announcements')
						}
						else if(index == '8') {
							if(this.vuex_user.openAgency) {
								this.jump('ec_mediation_guarantee')
							}else {
								console.log(this.vuex_user);
								this.uni.showToast({title:'您未开通中介担保',icon:'none'})
							}
						}
						else if(index == '9') {
							this.jump('published_data')
						}
						else if(index == '10') {
							this.showSub = true
						}
						console.log(this.checkIndex)
					},
	}
};
</script>
<style>
page {
	background: #fafafa;
}

</style>
<style lang="less" scoped>
// .disabled .el-upload--picture-card {
// 	display: none;
// }
@import "./editor-icon.css";
.enterprise {
		margin: 10px 18.75vw 6.25vw;
		
		.menuContent {
			background: #fff;
			padding: 1.5625vw 2.083vw;
			.el-form {
				margin-top: 3.125vw;
				.el-input {
					width: 400px;
				}
				.el-cascader {
					width: 400px;
				}
				.disabled /deep/.el-upload--picture-card {
					display: none;
				}
.avatar-uploader /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
			}
		}
	}
.page-body {
	height: 400px;
}
.wrapper {
	height: 100%;
}
.editor-wrapper {
	height:260px;
	background: #fff;
}
.iconfont {
	display: inline-block;
	padding: 8px 8px;
	width: 24px;
	height: 24px;
	cursor: pointer;
	font-size: 20px;
}
.toolbar {
	box-sizing: border-box;
	border-bottom: 0;
	font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
.ql-container {
	box-sizing: border-box;
	padding: 12px 15px;
	width: 100%;
	min-height: 30vw;
	height: 100%;
	margin-top: 20px;
	font-size: 16px;
	line-height: 1.5;
}
.ql-active {
	color: #06c;
}
</style>
