<template>
	<div>
		  <div class="enterprise">
		<div style="background-color: #fff;">
		<el-row>
          <gsLeft :userFormW="subform"></gsLeft>
			<el-col :span="20">
				<div class="menuContent">
		<div class="accountTitle">账号信息</div>
				<el-form label-position="left" label-width="5.8vw" :model="enterpriseAccount">
		<div class="tableContent">
			
			<div class="items">
				<div class="borderLeft">基本信息</div>
				<div class="mt">
					<el-form-item label="账号名："><el-input disabled v-model="enterpriseAccount.phone"></el-input></el-form-item>

					<el-form-item label="登录密码："><el-input show-password disabled v-model="enterpriseAccount.password"></el-input></el-form-item>
					<el-form-item ><el-button @click="dialogFormVisible = true">修改密码</el-button></el-form-item>
				</div>
					<el-dialog title="修改密码" width="40%" :visible.sync="dialogFormVisible">
  <el-form :model="editForm">
    <el-form-item label="账号名：" label-width="130px">
      <el-input disabled v-model="editForm.phone" autocomplete="off"></el-input>
    </el-form-item>
    <!-- <el-form-item label="登录密码：" label-width="120px">
      <el-input v-model="editForm.password" autocomplete="off"></el-input>

    </el-form-item> -->
		<el-form-item label="新的登录密码：" label-width="130px">
      <el-input v-model="editForm.newPwd" autocomplete="off" show-password></el-input>

    </el-form-item>
		<el-form-item label="验证码：" label-width="130px">
      <el-input v-model="editForm.captcha" autocomplete="off" clearable></el-input>
	<el-button class="yzm" :class="{'yzm-phone':editForm.phone && editForm.phone.length==11}" @click="sendCode(editForm.phone, 'FORGET','company')">{{codeText}}</el-button>
    </el-form-item>
		<el-form-item label-width="130px">
      <el-button class="yzm" style="margin-left:0" :class="{'yzm-phone':editForm.captcha}" @click="changePwd">确定</el-button>

    </el-form-item>
  </el-form>
</el-dialog>
				
			</div>

			<div class="items">
				<div class="borderLeft">联系方式</div>
					<div class="mt" v-if="enterpriseAccount.contracts">
          <el-radio v-model="contractsIndex" :label="index" v-for="(item,index) in enterpriseAccount.contracts" :key="'radio-'+index">{{item.name}}</el-radio>
        <div style="margin-top: 1.5625vw;" v-for="(item,idx) in enterpriseAccount.contracts" :key="idx">
          <div v-show="contractsIndex==idx">
                <el-form-item label="联系人：">
                  <el-input readonly v-model="item.contract" placeholder="请输入联系人姓名"></el-input>
                </el-form-item>
                <el-form-item label="对应称谓：">
                  <el-select disabled v-model="item.sex" placeholder="请选择" clearable>
                    <el-option label="先生" value="1"></el-option>
                    <el-option label="女士" value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="部门/职务：">
                  <el-input readonly v-model="item.job" placeholder="请输入部门/职务"></el-input>
                </el-form-item>
                <el-form-item label="固定电话：">
                  <el-input readonly v-model="item.phone" placeholder="请输入固定电话"></el-input>
                </el-form-item>
                <el-form-item label="QQ/微信：">
                  <el-input readonly v-model="item.msnQq" placeholder="请输入QQ/微信"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：">
                  <el-input readonly v-model="item.mobile" placeholder="请输入手机号码"></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱：">
                  <el-input readonly v-model="item.email" placeholder="请输入电子邮箱"></el-input>
                </el-form-item>
                <!-- <el-form-item label="中介担保:">
                  <el-switch @change="isAgentChage"
                    v-model="item.isAgent">
                  </el-switch>
                </el-form-item> -->
          </div>
        </div>
					</div>
			</div>
			<div class="items">
				<div class="borderLeft">企业信息</div>
				<!-- <el-form label-position="left" label-width="5.208vw" :model="enterpriseAccount"> -->
					<div class="mt">
					<el-form-item label="公司名称："><el-input readonly v-model="enterpriseAccount.name"></el-input></el-form-item>
					<el-form-item label="公司简称："><el-input readonly v-model="enterpriseAccount.simpleName"></el-input></el-form-item>
					<el-form-item label="公司地址："><el-input readonly v-model="enterpriseAccount.addr"></el-input></el-form-item>
					<el-form-item label="详细地址："><el-input readonly v-model="enterpriseAccount.detailAddr"></el-input></el-form-item>
					<el-form-item label="邮政编码："><el-input readonly v-model="enterpriseAccount.postalCode"></el-input></el-form-item>
					<el-form-item label="业务范围：">
						<div class="pleaseCheck">请选择贵公司的业务范围，最多5项</div>
            <el-row>
              <el-col :span="6" v-for="(item,idx) in businessList" :key="idx">
                <div>{{idx+1}}、{{item.name}}</div>
                <el-checkbox-group disabled v-model="checkList" :max="5" @change="handleCheckedCitiesChange">
                  <div v-for="(itm,idxs) in item.children" :key="idxs">
                    <el-checkbox :label="itm.name"></el-checkbox><br/>
                  </div>
                </el-checkbox-group>
              </el-col>
            </el-row>
					</el-form-item>
				
				
					<el-form-item label="公司网站："><el-input readonly v-model="enterpriseAccount.website"></el-input></el-form-item>
					<el-form-item label="公司简介：">
						<el-input readonly
						  type="textarea"
							:autosize="{ minRows: 6}"
						  v-model="enterpriseAccount.intro"
						  maxlength="1000"
						  show-word-limit
						>
						</el-input>
						</el-form-item>
					</div>
					<!-- </el-form> -->
			</div>
			<div class="items">
				<!-- <el-form label-position="left" label-width="5.208vw" :model="enterpriseAccount"> -->
					<el-form-item label="资讯订阅：">
						<el-radio disabled v-model="enterpriseAccount.subscribe" :label="true">订阅</el-radio>
					</el-form-item>
				
				<!-- </el-form> -->
			</div>
			
		</div>
		<div class="bottomBtn">
			<el-button type="primary" @click="jump('ec_account_manage_change')" style="background-color: #2089FF;">修改</el-button>
		</div>
		</el-form>
		</div>
		
		<el-dialog
				title="提示"
				:visible.sync="showSub"
				width="30%">
				<span v-if="subform.subscribe" style="margin-right:20px">是否关闭订阅</span>
				<span v-else style="margin-right:20px">是否开启订阅</span>
				<el-radio v-model="subscription" :label="true">是</el-radio>
				<el-radio v-model="subscription" :label="false">否</el-radio>
				<span slot="footer" class="dialog-footer">
					<el-button @click="showSub = false">取 消</el-button>
					<el-button type="primary" @click="editSub">确 定</el-button>
				</span>
			</el-dialog>
			</el-col>
						
		</el-row>
		</div>
  </div>
      
	</div>
</template>

<script>
import SmsMixin from '@/mixins/SmsMixin.vue'

import adver from '@/pages/advertisement.vue';
import BaseMixin from '@/mixins/BaseMixin.vue';
import gsLeft from "@/component/gs_left.vue";
export default {
	mixins: [BaseMixin, SmsMixin],
	data() {
		return {
			contractsIndex: 0,
			checkIndex: '2',
			dialogFormVisible: false,
			enterpriseAccount: {
				userName: 'abc123456',
				userPwd: '******',
				radio: 0,
				contacts: '',
				contracts: [],
				appellation: '先生',
				department: '',
				tel: '',
				WeChatorQQ: '',
				cellPhone: '',
				email: '',
				companyName: '',
				companyAddr: '',
				detailAddr: '',
				postalCode: '',
				checkedCities: ['船东/船舶操作', '船舶制造/维修/拆解/养护', '船舶备件/物料供应/配套', '货运代理/物流服务'],
				corporateWeb: '',
				companyProfile: '',
				subscribe: true
			},
			editForm: {},
			radio: 0,
			businessList: [],
			checkList: [],
			checkLists: [],
			showSub: false,
			subscription: true,
			subform: {}
		};
	},
	components: {
		adver,gsLeft
	},
	methods: {
		initData() {
			this.subform = {
				id: this.vuex_user.id,
				nickName: this.vuex_user.nickName,
				avatar: this.vuex_user.avatar,
				gender: this.vuex_user.gender,
				openId: this.vuex_user.openId,
				wxOpenid: this.vuex_user.wxOpenid,
				phone: this.vuex_user.phone,
				password: this.vuex_user.password,
				name: this.vuex_user.name,
				simpleName: this.vuex_user.simpleName,
				province: this.vuex_user.province,
				city: this.vuex_user.city,
				district: this.vuex_user.district,
				address: this.vuex_user.address,
				postalCode: this.vuex_user.postalCode,
				website: this.vuex_user.website,
				intro: this.vuex_user.intro,
				subscribe: this.vuex_user.subscribe,
				isPass: this.vuex_user.isPass,
				openAgency: this.vuex_user.openAgency,
				createTime: this.vuex_user.createTime,
				autid: this.vuex_user.autid
			}
			this.subscription = this.subform.subscribe
			
      this.smsApi = this.$u.api.verify

			this.$u.api.companyLoginInfo().then(res => {
				this.enterpriseAccount = {
					phone: res.data.phone,
					nickName: res.data.nickName,
					password: res.data.password,
					name: res.data.name,
					simpleName: res.data.simpleName,
					detailAddr: res.data.address,
					postalCode: res.data.postalCode,
					website: res.data.website,
					intro: res.data.intro,
					subscribe: res.data.subscribe
				}
				this.enterpriseAccount.addr = (res.data.province || '' ) + '/' + (res.data.city || '') + '/' + (res.data.district || '')
				this.editForm.phone = res.data.phone
				this.$u.api.getCompanyContracts().then(resp => {
					resp.data.forEach(e => {
						e.sex =	e.sex ? (e.sex + '') : ''
					})
					this.$set( this.enterpriseAccount, 'contracts', resp.data )
				})
			})
			
			
			
      this.$u.api.business().then(res => {
				
        this.businessList = res.data
      })
			this.$u.api.getBusiness().then(res => {
				res.data.forEach(e => {
					this.checkList.push(e.name)
				})
				// this.checkList = res.data
				
			})
		},
		changePwd() {
			if(!this.editForm.captcha) {
				this.uni.showToast('请输入验证码')
				return
			}
			this.$u.api.companyUpdatePassword(this.editForm.newPwd).then(res => {
				this.uni.showToast('修改成功')
				this.dialogFormVisible = false
			})
		},
    handleCheckedCitiesChange(e) {
      
      this.checkLists = []
      this.enterpriseAccount.businesses = []
      e.forEach(item => {
        this.businessList.forEach(e1 => {
          e1.children.forEach(e2 => {
            if (e2.name === item) {
              this.enterpriseAccount.businesses.push({id:e2.id})
            }
          })
        })
      })
    },
    isAgentChage(e) {
      this.enterpriseAccount.contracts.forEach((e,idx) => {
        e.isAgent = false
        if (this.contractsIndex === idx) {
          e.isAgent = e
        }
      })
      
    },
		editSub() {
			if(this.subscription) {
				this.$u.api.updateSubscribe(this.subscription+'').then(res => {
					this.uni.showToast({title:'订阅开启成功',icon:'none'})
					this.subform.subscribe = true
					this.$u.vuex('vuex_user', this.subform)
					this.showSub = false
					
				})
			}else {
				this.$u.api.updateSubscribe(this.subscription+'').then(res => {
					this.uni.showToast({title:'订阅关闭成功',icon:'none'})
					this.subform.subscribe = false
					this.$u.vuex('vuex_user', this.subform)
					this.showSub = false
					
				})
			}
			
		},
		check(index) {
			this.checkIndex = index
			if(index == '1') {
				this.jump('enterprise_index')
			}else if(index == '2') {
				this.jump('ec_account_manage')
			}
			else if(index == '3') {
				this.jump('ec_buy_package')
			}
			else if(index == '4-1') {
				
					if(this.vuex_user.isPass === true && this.vuex_user.autid != 2) {
						this.uni.showToast({
							title: '您的企业审核暂未通过',
							icon: 'none'
						});
						return
					}
					this.jump('post_recruitment_info')
			}
			else if(index == '4-2') {
				this.jump('manage_recruitment_info')
			}
			else if(index == '4-3') {
				this.jump('resume_delivery_received')
			}
			else if(index == '4-4') {
				this.jump('collected_resume')
			}
			else if(index == '5-1') {
				this.jump('ec_training_info')
			}
			else if(index == '5') {
				this.jump('ec_training_order')
			}
			else if(index == '5-3') {
				this.jump('training_school')
			}
			else if(index == '7') {
				
					if(this.vuex_user.isPass === true && this.vuex_user.autid != 2) {
						this.uni.showToast({
							title: '您的企业审核暂未通过',
							icon: 'none'
						});
						return
					}
					this.jump('corporate_announcements')
			}
			else if(index == '8') {
				// this.$u.api.companyLoginInfo().then(res => {
				// 	if(res.data.openAgency) {
					if(this.vuex_user.openAgency){
						this.jump('ec_mediation_guarantee')
					}else {
						this.uni.showToast({title:'您未开通中介担保',icon:'none'})
					}
				// })
			}
			else if(index == '9') {
				this.jump('published_data')
			}
			else if(index == '10') {
				this.showSub = true
			}
		},
	}
};
</script>
<style>
page {
	background: none;
}
 .el-form >>> .el-input .el-input__inner,
	 .el-form >>> .el-textarea .el-textarea__inner {
	   
	   font-size: 0.833vw;
	 }
 .el-form >>> .el-form-item__label {
	   font-size: 0.833vw;
	 }
.el-submenu .el-menu-item{
	min-width: 100%;
}
</style>
<style lang="less" scoped>
.enterprise {
		margin: 0  auto; width:68.5vw; /* 边距 */
		padding-top: 30px;box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);
		background: #fff;
		.menuContent {
			background: #fff;
			padding: 0.5vw 2.083vw 1.5625vw;
		}
		.yzm{
  margin-left:40px;
  width:300rpx;
  text-align:center;
    background:#e7e7e7;
    padding:30rpx;
    color:#999;font-size:1.15vw;
    border-radius: 10rpx;;
  }
		.yzm-phone{
    background:#2089FF;
    color:#fff;
  }
	}
	.accountTitle {
		font-size: 20px;
		font-weight: 500;
	}
	.items {
		margin-top: 3.125vw;
	}
.tableContent {
	// padding: 2.083vw 0 0 0;
	.el-input {
		width: 20.833vw;
	}
	.el-select {
		width: 20.833vw;
	}
	.borderLeft {
		border-left: 0.208vw solid #2089ff;
		font-size: 1.04vw;
		color: #333;
		font-weight: 500;
		padding: 0 0 0 0.52vw;
		margin: 0;
		line-height: 1.04vw;
	}
	.el-form,
	.el-table,
	.contactInfo {
	}
	.mt {
		margin-top: 2.083vw;
	}
	.pleaseCheck {
		font-size: 16px;
	}
	.other-business {
		margin-top: 3.125vw;
	}
	.checkOption {
		display: flex;
		.businessScope {
		width: 34%;
		div {
			font-size: 14px;
			font-weight: 600;
		}
		.el-checkbox-group {
			display: flex;
			flex-direction: column;
		}
	}
	}
	
}
.bottomBtn {
	
		margin-top: 3.385vw;
		margin-bottom: 3.125vw;
		margin-left: 100px;
		button {
			margin: 0;
			width: 6.25vw;
			font-size: 0.833vw;
		}
	}
</style>
