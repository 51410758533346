<script>
export default {
  data() {
    return {
      status: 'loadmore', //当前状态
      currentPage: {page: 0, size: 10}, //当前页和每页数量
      searchForm: {}, // 是否需要条件
      list: [], // 列表数据
      pageApi: null, // api地址
      complete: false, // 是否到底
      total: 0 // 列表数量
    }
  },
  onPullDownRefresh() {
    this.currentPage.page = 0
    this.initList()
  },
  // onReachBottom() {
  //   if (this.complete === false) {
  //     this.currentPage.page++
  //     this.getList()
  //   }
  // },
  methods: {
    initList() {
      this.currentPage.page = 0
      this.complete = false
      this.getList()
    },
    getList() {
      const param = Object.assign(this.searchForm, this.currentPage)
      if (this.pageApi === '') {
        return
      }
      this.status = 'loading'
      this.pageApi(param).then(res => {
        console.log(res)
        this.setPageList(res)
      }).catch(e => {
        if (this.currentPage.page > 0) {
          this.currentPage.page--
        }
      }).finally(() => {
        // this.uni.stopPullDownRefresh()
      })
    },
    setPageList(data) {
      console.log(data)
      if (this.currentPage.page === 0) {
        this.list = []
      }
      this.list = data.data.content||data.data
      this.total = data.data.total|| data.data.totalElements || this.list.length
      this.status = 'loadmore'
      if (this.list.length === this.total) {
        this.status = 'nomore'
        this.complete = true
      }
      this.reqComplete(data.data)
    },
    setListData(data) {
      return data || []
    },
    reqComplete(data) {
      // 网络请求完成
    }
  }
}
</script>

<style lang="scss">
	// @import "uview-ui/index.scss";
	/*每个页面公共css */
</style>
