<template>
	<div>
		<el-table ref="noticeTable1" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange"
			:header-cell-style="{
        'background-color': 'rgba(32, 137, 255, 0.07)',
        color: '#333',
        'font-size': '0.833vw',
        'font-weight': '400',
      }">
			<el-table-column type="selection"> </el-table-column>
			<el-table-column prop="category.label" label="公告类型"></el-table-column>
			<el-table-column prop="title" label="公告标题"></el-table-column>
			<el-table-column prop="status" label="显示状态" width="90">
				<template slot-scope="scope">
					<div>{{ scope.row.isShow ? "已显示" : "已隐藏" }}</div>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="发布时间">
				<template slot-scope="scope">
					{{ $dy_tool.formatDate(scope.row.createTime, "yyyy-MM-dd") }}
				</template>
			</el-table-column>
			<el-table-column label="操作" width="140">
				<template slot-scope="scope">
					<el-button type="text" @click="jump('notice_detail?id=' + scope.row.id)">详情</el-button>
					<el-button type="text"
						@click="jump('add_announcements?id=' + scope.row.id+'&category='+scope.row.category?.id)">编辑</el-button>
					<!-- <el-button type="text" @click="handleHide(scope.$index, scope.row)">{{scope.row.isShow ? '隐藏' : '显示'}}</el-button> -->
					<!-- <el-button type="text" @click="handleEdit(scope.$index, scope.row)">{{scope.row.isSend ? '取消发布' : '发布'}}</el-button> -->
					<el-button type="text" @click="handleDel(scope.$index, scope.row)">删除</el-button>
					<el-button type="text" @click="handleRefresh(scope.$index, scope.row)">刷新</el-button>
					<el-button type="text" @click="handleTop(scope.$index, scope.row)">我要置顶</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
	import BaseMixin from "@/mixins/BaseMixin.vue";
	export default {
		mixins: [BaseMixin],
		props: {
			tableData: Array,
		},
		methods: {
			handleDel(index, row) {
				this.$dialog.confirm({
					title: "温馨提示",
					message: "是否确认删除"
				}).then(() => {
					this.$u.api.delCompanyArticle([row.id]).then((res) => {
						this.uni.showToast("公告删除成功");
					});
				});
			},
			handleRefresh(index, row) {
				this.$u.api.companyRefreshTsa({
					id: row.id
				}).then((res) => {
					this.uni.showToast("刷新成功");
				});
				console.log(index, row);
			},
			handleTop(index, row) {
				this.$u.api.companyTopTsa({
					id: row.id
				}).then((res) => {
					this.uni.showToast("置顶成功");
				});
				console.log(index, row);
			},
			handleSelectionChange(val) {
				this.$emit('handleSelectionChange', val)
			}
		},
	};
</script>
<style scoped></style>